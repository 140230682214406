import {Checkbox, FormControlLabel} from '@mui/material'
import {Field} from 'formik'

interface Props {
  field: string
  value: string
}

export function CheckBox({field, value}: Props) {
  return (
    <Field formik name={field} type='checkbox' value={value}>
      {({field}: any) => <FormControlLabel control={<Checkbox {...field} />} label={value} />}
    </Field>
  )
}
