import {useState} from 'react'
import {Edit, Add, Delete} from '@mui/icons-material'
import {Chip} from '@mui/material'
import {CreateGallery} from './CreateGallery'
import {UpdateGallery} from './UpdateGallery'
import {DeleteGallery} from './DeleteGallery'

export function NavigationGallery() {
  const [value, setValue] = useState(0)

  function setView(value: number) {
    switch (value) {
      case 0:
        return <CreateGallery />
      case 1:
        return <UpdateGallery />
      case 2:
        return <DeleteGallery />
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 cursor-pointer d-flex justify-content-between'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>
            Avance de obra - {value === 0 ? 'crear' : value === 1 ? 'actualizar' : 'eliminar'}
          </h3>
        </div>
        <div className='d-flex gap-3 mt-5'>
          <Chip
            icon={<Add />}
            label='Crear'
            variant={value === 0 ? 'filled' : 'outlined'}
            onClick={() => setValue(0)}
          />
          <Chip
            icon={<Edit />}
            label='Editar'
            variant={value === 1 ? 'filled' : 'outlined'}
            onClick={() => setValue(1)}
          />
          <Chip
            icon={<Delete />}
            label='Eliminar'
            variant={value === 2 ? 'filled' : 'outlined'}
            onClick={() => setValue(2)}
          />
        </div>
      </div>

      <div id='kt_account_signin_method'>
        <div className='card-body border-top p-9'>
          <div className='d-flex flex-wrap align-items-center'>
            <div id='kt_signin_email_edit' className={'flex-row-fluid '}>
              <div className='row mb-6'>{setView(value)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
