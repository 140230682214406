export const updatePropertyAdapter  = (data: any) => {
    return {
    title: data.title,
    address: data.address,
    publicAddress: data.publicAddress,
    rooms: data.rooms,
    baths: data.baths,
    balconies: data.balconies,
    garages: data.garages,
    deposits: data.deposits,
    requiredAmmount: data.requiredAmmount,
    minimumAmount: data.minimumAmount,
    maximumAmount: data.maximumAmount,
    estimatedTerm: data.estimatedTerm,
    termToInvest: data.termToInvest,
    builderName: data.builderName,
    logo: data.logo,
    nit: data.nit,
    socialReason: data.socialReason,
    epaycoAPiKey: data.epaycoAPiKey,
    epaycoPrivateKey: data.epaycoPrivateKey,
    tower: data.tower,
    apartment: data.apartment,
    virtualTour: data.virtualTour,
    description: data.description,
    propertyType: data.propertyType,
    area: data.area,
    status: data.status,
    propertySecurity: data.propertySecurity,
    propertySetFeatures: data.propertySetFeatures,
    propertyNearby: data.propertyNearby,
    estimatedProfitability: data.estimatedProfitability,
    photos: data.photos,
    video: data.video,
    location: data.location,
    completionPercentage: data.completionPercentage,
    fundedPercentage: data.fundedPercentage
    }
  }