/* eslint-disable react-hooks/exhaustive-deps */
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material'
import axios from 'axios'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import {alertError, alertSuccess} from '../../../_metronic/helpers/alerts'

const API_URL = process.env.REACT_APP_SERVER_BASE_URL

export function DeleteGallery() {
  const [galleries, setGalleries] = useState<any[]>([])
  const [galleryId, setGalleryId] = useState<any>('')
  const {id} = useParams()

  const handleSubmit = async () => {
    try {
      if (galleryId === '') {
        alertError('Seleccione una opción', 2000)
        return
      }
      await axios.delete(`${API_URL}/gallery/${galleryId}`)
      alertSuccess('Avance de obra eliminado correctamente')
      setGalleryId('')
    } catch (error) {
      alertError('No se ha podido eliminar el avance de obra', 2500)
    }
  }

  const getGalleries = async () => {
    const res = await axios.get(`${API_URL}/gallery`)
    setGalleries(res.data.filter((gallery: any) => gallery.saleBrick === id))
  }

  useEffect(() => {
    getGalleries()
  }, [galleryId])

  return (
    <div>
      <div className='d-flex flex-wrap align-items-center'>
        <div id='kt_signin_email_edit' className={'flex-row-fluid '}>
          <div className='col-lg-12 mb-4 mb-lg-0'>
            <div className='fv-row mb-0'>
              <label htmlFor='galleryName' className='form-label fs-6 fw-bolder mb-5'>
                Seleccione una galería
              </label>
              <FormControl fullWidth>
                <InputLabel id='stateLabel'>Galerías</InputLabel>
                <Select
                  labelId='statusLabel'
                  id='demo-simple-select'
                  label='Galerías'
                  name='status'
                  value={galleryId}
                  onChange={(e) => setGalleryId(e.target.value)}
                >
                  <MenuItem value={''}>Seleccione una opción</MenuItem>
                  {galleries.length !== 0 &&
                    galleries.map((gallery: any) => (
                      <MenuItem key={gallery.id} value={gallery.id}>
                        {gallery.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-4 px-9 mt-4'>
            <button type='submit' className={`btn btn-danger`} onClick={handleSubmit}>
              Eliminar avance
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
