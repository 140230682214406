import {useContext, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {Documents} from './forms/Documents'
import {BasicInformationForm} from './forms/BasicInformationForm'
import {FinancialInformation} from './forms/FinancialInformation'
import {InitialInformation} from './forms/InitialInformation'
import {Pep} from './forms/Pep'
import {ProfessionalInformation} from './forms/ProfessionalInformation'
import {SocketContext} from '../context/SocketContext'
import {ToastContainer} from 'react-toastify'
import {alertSuccess} from '../../../_metronic/helpers/alerts'
import {useMultipleGetDataHook} from '../hooks/useMultipleGetDataHook'
import {UserContext} from '../context/UserProvider'

export default function DetailInvestor() {
  const {id} = useParams()
  const {socket, setInvestors} = useContext(SocketContext)
  const {getOneInvestorData} = useMultipleGetDataHook()
  const {setInvestor} = useContext(UserContext)

  const handleVerify = () => {
    try {
      socket?.put(`/investors/${id}`, {status: true}, () => {
        socket?.get('/investors', (data: any) => {
          setInvestors(data)
        })
      })
      alertSuccess('El usuario ha sido verificado')
    } catch (error: any) {
      console.log(error.response)
    }
  }

  useEffect(() => {
    socket?.get('/investors', async (data: any) => {
      const res = await getOneInvestorData(data.find((investor: any) => investor.id === id))
      setInvestor(res)
      setInvestors(data)
    })
  }, [socket])

  return (
    <>
      <BasicInformationForm />
      <InitialInformation />
      <ProfessionalInformation />
      <FinancialInformation />
      <Pep />
      <Documents />

      <div className='card-footer d-flex justify-content-end py-4 px-9'>
        <button type='submit' className={`btn btn-primary`} onClick={handleVerify}>
          Verificar usuario
        </button>
        <ToastContainer />
      </div>
    </>
  )
}
