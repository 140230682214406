import {useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import DeleteProperty from './DeleteProperty'
import {alertSuccess} from '../../../_metronic/helpers/alerts'
import {
  Typography,
  Box,
  LinearProgress,
  LinearProgressProps,
  Chip,
  Tooltip,
  IconButton,
  Stack,
} from '@mui/material'
import {Edit, Delete, AddAPhoto, Paid} from '@mui/icons-material'
import {ToastContainer} from 'react-toastify'
import {UserContext} from '../context/UserProvider'
import {MUITable} from '../components/MUITable'
import axios from 'axios'

interface PropertyData {
  address: string
  balconies: number
  baths: number
  builder: any
  city: string
  completionPercentage: number
  country: string
  createdAt: number
  department: string
  deposits: number
  estimatedProfitability: string
  estimatedTerm: string
  fundedPercentage: number
  garages: number
  id: string
  isFunded: false
  locality: string
  location: []
  maximumAmount: number
  minimumAmount: number
  neighborhood: string
  photos: []
  publicAddress: string
  requiredAmmount: string
  rooms: number
  status: string
  title: string
  updatedAt: number
  videos: []
  zone: string
}

const API_URL = process.env.REACT_APP_SERVER_BASE_URL

export function ListProperties() {
  const [open, setOpen] = useState(false)
  const [properties, setProperties] = useState<PropertyData[]>([])
  const [propertyId, setPropertyId] = useState<string | null>(null)
  const {setProperty, initialState} = useContext(UserContext)
  const [action, setAction] = useState<string | null>(null)
  const navigate = useNavigate()

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const handleAlert = () => alertSuccess('Inmueble eliminado con éxito')

  const getPropertiesData = async () => {
    const res = await axios.get(`${API_URL}/properties`)
    setProperties(res.data)
  }

  useEffect(() => {
    if (propertyId) {
      switch (action) {
        case 'update':
          setProperty(initialState)
          navigate(`/properties/update/${propertyId}`)
          break
        case 'gallery':
          navigate(`/properties/gallery/${propertyId}`)
          break
        case 'investment':
          navigate(`/properties/investment/${propertyId}`)
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId, action])

  useEffect(() => {
    getPropertiesData()
  }, [])

  function LinearProgressWithLabel(props: LinearProgressProps & {value: number}) {
    return (
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box sx={{width: '100%', mr: 1}}>
          <LinearProgress variant='determinate' {...props} />
        </Box>
        <Box sx={{minWidth: 35}}>
          <Typography variant='body1' color='text.secondary'>{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  function setChipColor(value: string) {
    switch (value) {
      case 'actived':
        return 'info'
      case 'founded':
        return 'success'
      case 'paid':
        return 'error'
      case 'soon':
        return 'warning'
      case 'disabled':
        return 'default'
    }
  }

  function setStatusValue(value: string) {
    switch (value) {
      case 'actived':
        return 'Activo'
      case 'founded':
        return 'Meta cumplida'
      case 'paid':
        return 'Pagado'
      case 'soon':
        return 'Próximamente'
      case 'disabled':
        return 'Desactivado'
    }
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'title',
      label: 'Nombre',
    },
    {
      name: 'termToInvest',
      label: 'Termino para invertir',
      options: {
        customBodyRender: (value: string) => {
          return <Typography sx={{paddingLeft: 2}}>{value}</Typography>
        },
      },
    },
    {
      name: 'builderName',
      label: 'Propietario',
    },
    {
      name: 'status',
      label: 'Estado',
      options: {
        customBodyRender: (value: string) => {
          return (
            <Chip
              label={setStatusValue(value)}
              color={setChipColor(value)}
              size='small'
              sx={{width: '90px'}}
            />
          )
        },
      },
    },
    {
      name: 'fundedPercentage',
      label: '% Meta cumplida',
      options: {
        customBodyRender: (value: string) => {
          return <LinearProgressWithLabel variant='determinate' value={parseInt(value)} />
        },
      },
    },
    {
      name: 'completionPercentage',
      label: '% Completado',
      options: {
        customBodyRender: (value: string) => {
          return <LinearProgressWithLabel variant='determinate' value={parseInt(value)} />
        },
      },
    },
    {
      name: 'status',
      label: 'Acciones',
      options: {
        customBodyRender: (value: string, {rowData}: any) => {
          return (
            <>
              <Stack direction='row' spacing={1}>
                <Tooltip title='Actualizar'>
                  <IconButton color='warning' onClick={() => setAction('update')}>
                    <Edit />
                  </IconButton>
                </Tooltip>

                <Tooltip title='Avance de obra'>
                  <IconButton color='info' onClick={() => setAction('gallery')}>
                    <AddAPhoto />
                  </IconButton>
                </Tooltip>

                <Tooltip title='Eliminar'>
                  <IconButton
                    color={rowData[5] > 0 ? 'default' : 'error'}
                    onClick={rowData[5] > 0 ? () => {} : handleOpen}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Realizar inversión'>
                  <IconButton
                    color={value === 'actived' ? 'success' : 'default'}
                    onClick={value === 'actived' ? () => setAction('investment') : () => {}}
                  >
                    <Paid />
                  </IconButton>
                </Tooltip>
              </Stack>
            </>
          )
        },
        filter: false,
      },
    },
  ]

  return (
    <>
      <Box
        className='cursor-pointer bg-body'
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: '5px',
          textAlign: 'center',
        }}
        mb={2}
      >
        <h3 className='m-0'>Inmuebles</h3>
      </Box>

      <MUITable title='Inmuebles' columns={columns} data={properties} setId={setPropertyId} />
      {open && (
        <DeleteProperty
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          handleAlert={handleAlert}
          propertyId={propertyId}
          properties={properties}
          setProperties={setProperties}
        />
      )}

      <ToastContainer />
    </>
  )
}
