import axios from 'axios'
import {useRef, useState} from 'react'
import {makeStyles} from '@mui/styles'
import {alertError, alertWarning} from '../../../_metronic/helpers/alerts'
import {AddPhotoAlternate, Clear, SettingsBackupRestore} from '@mui/icons-material'
import {Avatar, Typography, Box, Fab, Tooltip, IconButton, Stack} from '@mui/material'
import {ToastContainer} from 'react-toastify'
import {GridContextProvider, GridDropZone, GridItem, swap} from 'react-grid-dnd'

interface Props {
  imageUrls: any[]
  gallery: any
  setGallery: any
  setImageUrls: React.Dispatch<React.SetStateAction<any[]>>
  setIsLoadingImages: React.Dispatch<React.SetStateAction<boolean>>
}

const useStyles = makeStyles({
  hover: {
    '&:hover': {
      backgroundColor: 'rgb(0, 0, 0, 0.2)',
      transition: 'background-color 0.8s ease',
    },
    transition: 'background-color 0.7s ease',
  },
})

const API_URL = process.env.REACT_APP_SERVER_BASE_URL

export function ImageUploader({
  imageUrls,
  gallery,
  setImageUrls,
  setIsLoadingImages,
  setGallery,
}: Props) {
  const [activeButtons, setActiveButtons] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const fileInput = useRef<HTMLInputElement>(null)

  const classes = useStyles()

  const handleResetUrlImages = () => {
    setImageUrls([])
    setGallery((prevState: any) => ({
      ...prevState,
      gallery: [],
    }))
  }

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoadingImages(true)
      const files = event.target.files

      if (!files) {
        alertWarning('No ha cargado ningún archivo', 2000)
        return
      }
      if (gallery && files.length + gallery?.length > 15) {
        alertWarning('Solamente puede cargar máximo 15 imagenes', 3000)
        return
      }

      const formData = new FormData()

      const imageUrlsArray: any[] = []

      Array.from(files).forEach((file: any, index) => {
        formData.append(`images`, file, `${Math.floor(Math.random() * 1000000)}.jpg`)

        const reader = new FileReader()
        reader.onloadend = () => {
          imageUrlsArray.push(reader.result)
          setImageUrls(imageUrlsArray)
        }
        reader.readAsDataURL(file)
      })

      const res = await axios.post(`${API_URL}/images/upload-many`, formData)
      setGallery((prevState: any) => ({
        ...prevState,
        gallery: [...prevState.gallery, ...res.data.names],
      }))
      setIsLoadingImages(false)
    } catch (error) {
      handleResetUrlImages()
      setIsLoadingImages(false)
      alertError('El tiempo de carga de imagenes excedió', 4000)
    }
  }

  const handleRemoveOneImage = (id: string) => {
    setImageUrls([])
    setGallery((prevState: any) => ({
      ...prevState,
      gallery: gallery.filter((url: string, index: string) => {
        if (index !== id) {
          return gallery[index]
        }
        return null
      }),
    }))
  }

  const handleImageUpload = () => {
    fileInput.current?.click()
  }

  const handleDnDChange = (sourceId: any, sourceIndex: any, targetIndex: any, targetId: any) => {
    const nextState = swap(gallery, sourceIndex, targetIndex)
    setGallery((prevState: any) => ({
      ...prevState,
      gallery: nextState,
    }))
  }

  const handleMouseOver = () => setActiveButtons(true)

  const handleMouseOut = () => setActiveButtons(false)

  return (
    <Box sx={{width: '100%'}} mt={imageUrls.length !== 0 ? 0 : 3}>
      <input
        ref={fileInput}
        type='file'
        onChange={handleImageChange}
        style={{display: 'none'}}
        multiple
        accept={'image/*'}
      />

      {imageUrls.length !== 0 || gallery?.length !== 0 ? (
        <Box
          mt={2}
          sx={{padding: 4, borderRadius: '5px', position: 'relative'}}
          className={classes.hover}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {activeButtons && (
            <Box sx={{position: 'absolute', top: 1, right: 1}}>
              <Stack direction='row'>
                <Tooltip title='Subir imagenes' onClick={handleImageUpload}>
                  <IconButton size='large'>
                    <AddPhotoAlternate sx={{fontSize: '25px'}} />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Restablecer' onClick={handleResetUrlImages}>
                  <IconButton size='large'>
                    <SettingsBackupRestore sx={{fontSize: '25px'}} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Box>
          )}
          <GridContextProvider onChange={handleDnDChange}>
            <GridDropZone
              id='photos'
              boxesPerRow={8}
              rowHeight={120}
              style={{height: '230px', marginTop: 30}}
            >
              {gallery?.length !== 0 &&
                gallery?.map((url: any, index: string) => (
                  <GridItem key={url}>
                    <Box sx={{position: 'relative'}}>
                      {isHover && (
                        <Clear sx={{position: 'absolute', top: 10, left: 8, fontSize: '80px'}} />
                      )}
                      <Avatar
                        alt='images'
                        src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${url}`}
                        sx={{
                          width: 95,
                          height: 95,
                          cursor: 'pointer',
                          position: 'relative',
                          '&:hover': {
                            opacity: isHover ? 0.5 : 1,
                          },
                        }}
                        onClick={() => handleRemoveOneImage(index)}
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                      />
                    </Box>
                  </GridItem>
                ))}
            </GridDropZone>
          </GridContextProvider>
        </Box>
      ) : (
        <Fab variant='extended' onClick={handleImageUpload}>
          <AddPhotoAlternate sx={{mr: 2}} fontSize='large' />
          <Typography>Seleccionar</Typography>
        </Fab>
      )}
      <ToastContainer />
    </Box>
  )
}
