import { TextField } from '@mui/material'
import { useFormik } from 'formik'

interface Props {
  field: string
  type?: string
  label?: string
  formik: ReturnType<typeof useFormik>
  required?: boolean
  multiline?: boolean
  rows?: number
}

export function Input({
  field,
  label,
  formik,
  type = 'text',
  required = true,
  multiline = false,
  rows,
}: Props) {
  return (
    <TextField
      className='form-control'
      type={type}
      label={label}
      required={required}
      multiple={multiline}
      maxRows={rows}
      {...formik.getFieldProps(field)}
      error={formik.touched[field] && formik.errors[field] ? true : false}
      helperText={(formik.touched[field] && formik.errors[field]) as string}
    />
  )
}
