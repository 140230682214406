/* eslint-disable react-hooks/exhaustive-deps */
import {
	Alert,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material'
import axios from 'axios'
import { FormikValues, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { alertError, alertSuccess } from '../../../_metronic/helpers/alerts'
import { ImageUploader } from './ImageUploader'
import { gallerySchema } from './schemas/GallerySchema'

const API_URL = process.env.REACT_APP_SERVER_BASE_URL

export function UpdateGallery() {
  const [activeAlert, setActiveAlert] = useState(false)
  const [imageUrls, setImageUrls] = useState<any[]>([])
  const [galleries, setGalleries] = useState<any[]>([])
  const [galleryId, setGalleryId] = useState<any>('')
  const [isLoadingImages, setIsLoadingImages] = useState(false)
  const {id} = useParams()

  const initialValues = {
    name: '',
    gallery: [],
    saleBrick: id,
  }

  const [gallery, setGallery] = useState<any>({})

  const formik = useFormik({
    initialValues: initialValues as FormikValues,
    validationSchema: gallerySchema,
    onSubmit: async (values, {resetForm}) => {
      try {
        if (gallery.gallery.length === 0 || gallery.gallery.length > 15) {
          setActiveAlert(true)
          setTimeout(() => {
            setActiveAlert(false)
          }, 3000)
          return
        }
        const obj = {
          name: values.name,
          gallery: gallery.gallery,
          saleBrick: gallery.saleBrick,
        }
        await axios.put(`${API_URL}/gallery/${galleryId}`, obj)
        alertSuccess('Avance de obra actualizado correctamente')

        resetForm()
        setGalleryId('')
      } catch (error) {
        alertError('Ha ocurrido un error', 3000)
      }
    },
  })

  const getGalleries = async () => {
    const res = await axios.get(`${API_URL}/gallery`)
    setGalleries(res.data.filter((gallery: any) => gallery.saleBrick === id))
  }

  const getGallery = () => {
    if (galleryId === '') {
      formik.setFieldValue('name', '')
    }
    setGallery(galleries.find((gallery: any) => gallery.id === galleryId))
  }

  useEffect(() => {
    getGalleries()
  }, [galleryId])

  useEffect(() => {
    getGallery()
  }, [galleryId])

  useEffect(() => {
    if (gallery && gallery.hasOwnProperty('name')) {
      if (formik.getFieldProps('name').value === '') formik.setFieldValue('name', gallery.name)
    }
  }, [gallery])

  return (
    <div>
      <div className='d-flex flex-wrap align-items-center'>
        <div id='kt_signin_email_edit' className={'flex-row-fluid '}>
          <form onSubmit={formik.handleSubmit} className='form' noValidate>
            {activeAlert && (
              <Alert severity='error' sx={{marginTop: 2, marginBottom: 4}}>
                <Typography sx={{fontSize: '16px'}}>¡Carga todos los archivos!</Typography>
              </Alert>
            )}
            <div className='col-lg-12 mb-4 mb-lg-0'>
              <div className='fv-row mb-0'>
                <label htmlFor='galleryName' className='form-label fs-6 fw-bolder mb-5'>
                  Seleccione una galería
                </label>
                <FormControl fullWidth>
                  <InputLabel id='stateLabel'>Galerías</InputLabel>
                  <Select
                    labelId='statusLabel'
                    id='demo-simple-select'
                    label='Galerías'
                    name='status'
                    value={galleryId}
                    onChange={(e) => setGalleryId(e.target.value)}
                    error={formik.touched.status && formik.errors.status ? true : false}
                  >
                    <MenuItem value={''}>Seleccione una opción</MenuItem>
                    {galleries.length !== 0 &&
                      galleries.map((gallery: any) => (
                        <MenuItem key={gallery.id} value={gallery.id}>
                          {gallery.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className='col-lg-12 mb-4 mb-lg-0'>
              <div className='fv-row mb-0'>
                <label htmlFor='galleryName' className='form-label fs-6 fw-bolder mb-3 mt-6'>
                  Ingrese el nombre del avance
                </label>
                <TextField
                  placeholder='Nombre'
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  required
                  name='name'
                  className='form-control'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && formik.errors.name ? true : false}
                  helperText={(formik.touched.name && formik.errors.name) as string}
                />
              </div>
            </div>

            <div className='col-lg-12 mt-5 mb-lg-0'>
              <div className='fv-row mb-0'>
                <label htmlFor='gallery' className='form-label fs-6 fw-bolder'>
                  Cargue las imagenes
                </label>
                <ImageUploader
                  imageUrls={imageUrls}
                  gallery={gallery?.gallery}
                  setGallery={setGallery}
                  setImageUrls={setImageUrls}
                  setIsLoadingImages={setIsLoadingImages}
                />
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-4 px-9 mt-4'>
              <button type='submit' className={`btn btn-primary`} disabled={isLoadingImages}>
                {isLoadingImages ? 'Cargando imagenes...' : 'Guardar avance'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
