import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import DetailInvestor from './DetailInvestsor'
import {ListInvestors} from './InvestorsList'

const investorBreadCrumbs: Array<PageLink> = [
  {
    title: 'Gestionar',
    path: '/investors/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const InvestorPage = () => (
  <Routes>
    <Route
      element={
        <>
          {/* <ProfileHeader /> */}
          <Outlet />
        </>
      }
    >
      <Route
        path='list'
        element={
          <>
            <PageTitle breadcrumbs={investorBreadCrumbs}>Inversionistas</PageTitle>
            {/* <Campaigns /> */}
            <ListInvestors />
          </>
        }
      />
      <Route
        path='detail/:id'
        element={
          <>
            <PageTitle breadcrumbs={investorBreadCrumbs}>Inversionistas</PageTitle>
            {/* <Campaigns /> */}
            <DetailInvestor />
          </>
        }
      />
      <Route index element={<Navigate to='/builders' />} />
    </Route>
  </Routes>
)

export default InvestorPage
