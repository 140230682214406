import {useContext} from 'react'
import File from '../File'
import {UserContext} from '../../context/UserProvider'

export function Documents() {
  const {investor} = useContext(UserContext)

  const extensions = {
    identificationDocument: `${investor?.extendedIdCard}`.substring(
      `${investor?.extendedIdCard}`.length - 3
    ),
    rut: `${investor?.RUTNaturalPerson}`.substring(`${investor?.RUTNaturalPerson}`.length - 3),
    bankAccountCertificate: `${investor?.bankAccountCertificate}`.substring(
      `${investor?.bankAccountCertificate}`.length - 3
    ),
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details6'
        aria-expanded='true'
        aria-controls='kt_account_profile_details6'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Documentos</h3>
        </div>
      </div>

      <div id='kt_account_profile_details6' className='collapse show'>
        <form noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Documento de identidad
              </label>

              <div className='col-lg-8 fv-row d-flex justify-content-center'>
                {investor?.extendedIdCard && (
                  <File
                    url={`${investor?.extendedIdCard}`}
                    extension={extensions?.identificationDocument}
                  />
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                RUT persona natural
              </label>

              <div className='col-lg-8 fv-row d-flex justify-content-center'>
                {investor?.RUTNaturalPerson && (
                  <File url={`${investor?.RUTNaturalPerson}`} extension={extensions?.rut} />
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Certificación de cuenta bancaria</span>
              </label>

              <div className='col-lg-8 fv-row d-flex justify-content-center'>
                {investor?.bankAccountCertificate && (
                  <File
                    url={`${investor?.bankAccountCertificate}`}
                    extension={extensions.bankAccountCertificate}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
