import * as Yup from 'yup'

export const validationBasicInformationSchema = Yup.object().shape({
  title: Yup.string().required('Este campo es obligatorio'),
  address: Yup.string().required('Este campo es obligatorio'),
  publicAddress: Yup.string().required('Este campo es obligatorio'),
  rooms: Yup.number().required('Este campo es obligatorio'),
  baths: Yup.number().required('Este campo es obligatorio'),
  balconies: Yup.number().required('Este campo es obligatorio'),
  garages: Yup.number().required('Este campo es obligatorio'),
  deposits: Yup.number().required('Este campo es obligatorio'),
  tower: Yup.string().required('Este campo es obligatorio'),
  apartment: Yup.string().required('Este campo es obligatorio'),
  virtualTour: Yup.string(),
  description: Yup.string(),
  propertyType: Yup.string().required('Este campo es obligatorio'),
  area: Yup.number().required('Este campo es obligatorio'),
})

export const validationAdditionalInformationSchema = Yup.object().shape({
  requiredAmmount: Yup.number().required('Este campo es obligatorio'),
  minimumAmount: Yup.number().required('Este campo es obligatorio'),
  estimatedTerm: Yup.number().required('Este campo es obligatorio'),
  estimatedProfitability: Yup.string().required('Este campo es obligatorio'),
  status: Yup.string().required('Este campo es obligatorio'),
  termToInvest: Yup.string().required('Este campo es obligatorio'),
  completionPercentage: Yup.number().required('Este campo es obligatorio'),
})

export const validationOwnerSchema = Yup.object().shape({
  builderName: Yup.string().required('Este campo es obligatorio'),
  nit: Yup.string().required('Este campo es obligatorio'),
  socialReason: Yup.string().required('Este campo es obligatorio'),
  epaycoAPiKey: Yup.string(),
  epaycoPrivateKey: Yup.string(),
})

export const validationInvestmentSchema = Yup.object().shape({
  propertyId: Yup.string().required('Este campo es obligatorio'),
  investorId: Yup.string().required('Este campo es obligatorio'),
  holdingValue: Yup.number()
    .min(1, 'El porcentaje de inversión debe ser superior a 0')
    .required('Este campo es obligatorio'),
})

export const validationFilesSchema = Yup.object().shape({
  video: Yup.string().matches(
    /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]+$/,
    'La URL proporcionada no es válida'
  ),
})
