import {createContext, useState} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import {PropertyFrontendModel} from '../properties/models/PropertyModel'
type UserContextProps = {
  property: PropertyFrontendModel
  setProperty: React.Dispatch<React.SetStateAction<PropertyFrontendModel>>
  investor: any
  setInvestor: any
  initialState: PropertyFrontendModel
}
const UserContext = createContext<UserContextProps>({} as UserContextProps)

const UserProvider = ({children}: WithChildren) => {
  const initialState = {
    title: '',
    address: '',
    publicAddress: '',
    rooms: '',
    baths: '',
    balconies: '',
    garages: '',
    deposits: '',
    requiredAmmount: '',
    minimumAmount: '',
    estimatedTerm: '',
    termToInvest: '',
    builderName: '',
    logo: '',
    nit: '',
    socialReason: '',
    epaycoAPiKey: '',
    epaycoPrivateKey: '',
    tower: '',
    apartment: '',
    virtualTour: '',
    description: '',
    propertyType: '',
    area: '',
    status: 'disabled',
    propertySecurity: [],
    propertySetFeatures: [],
    propertyNearby: [],
    estimatedProfitability: '',
    photos: [],
    video: '',
    location: [],
    completionPercentage: 0,
    fundedPercentage: 0,
  }
  const [property, setProperty] = useState<PropertyFrontendModel>(initialState)
  const [investor, setInvestor] = useState([])

  return (
    <UserContext.Provider
      value={{
        property,
        investor,
        setInvestor,
        setProperty,
        initialState,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export {UserProvider, UserContext}
