import {AddPhotoAlternate, SettingsBackupRestore} from '@mui/icons-material'
import {Alert, Avatar, Fab, Grid, IconButton, Stack, Tooltip, Typography} from '@mui/material'
import {Box} from '@mui/system'
import {FormikValues, useFormik} from 'formik'
import {useContext, useRef, useState} from 'react'
import {validationOwnerSchema} from '../schemas/PropertySchema'
import {makeStyles} from '@mui/styles'
import {UserContext} from '../../context/UserProvider'
import {Input} from '../components/Input'
import {alertError} from '../../../../_metronic/helpers/alerts'
import axios from 'axios'

interface Props {
  handleNext: () => void
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

export default function OwnerForm({handleNext}: Props) {
  const {property, setProperty} = useContext(UserContext)
  const [activeButtons, setActiveButtons] = useState(false)
  const [activeAlert, setActiveAlert] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [builderLogo, setBuilderLogo] = useState({
    logo: property.logo,
  })
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(
    builderLogo.logo ? `${URL}/images/${builderLogo.logo}` : ''
  )
  const inputFileRef = useRef<HTMLInputElement | null>(null)

  const initialValues = {
    builderName: property.builderName,
    nit: property.nit,
    socialReason: property.socialReason,
    epaycoAPiKey: property.epaycoAPiKey,
    epaycoPrivateKey: property.epaycoPrivateKey,
  }

  const useStyles = makeStyles({
    hover: {
      '&:hover': {
        backgroundColor: 'rgb(0, 0, 0, 0.2)',
        transition: 'background-color 0.8s ease',
      },
      transition: 'background-color 0.7s ease',
    },
  })

  const classes = useStyles()

  const handleChangeProperty = (key: string, value: string | object) => {
    setProperty((prevState: any) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleImageUpload = () => {
    inputFileRef.current?.click()
  }
  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.files) {
        setIsLoadingImage(true)
        const file = event.target.files[0]

        const formData = new FormData()
        formData.append('image', file)
        const res = await axios.post(`${URL}/images/upload-one`, formData)

        setBuilderLogo((prevState) => ({
          ...prevState,
          logo: res.data.name,
        }))

        const reader = new FileReader()
        reader.onloadend = () => {
          setImageUrl(reader.result)
        }
        reader.readAsDataURL(file)
        setIsLoadingImage(false)
      }
    } catch (error) {
      alertError('Ha ocurrido un error al cargar la imagen', 4000)
    }
  }

  const handleResetUrlImage = () => {
    setImageUrl(null)
  }

  const handleMouseOver = () => setActiveButtons(true)

  const handleMouseOut = () => setActiveButtons(false)

  const handleSubmit = async (values: FormikValues) => {
    try {
      const {builderName, nit, socialReason, epaycoAPiKey, epaycoPrivateKey} = values

      if (!imageUrl) {
        setActiveAlert(true)
        setTimeout(() => {
          setActiveAlert(false)
        }, 3000)
        return
      }

      const owner = {
        builderName,
        logo: builderLogo.logo,
        nit,
        socialReason,
        epaycoAPiKey,
        epaycoPrivateKey,
      }

      let i: number = 0
      Object.keys(owner).forEach((property) => {
        handleChangeProperty(property, Object.values(owner)[i])
        i++
      })

      handleNext()
    } catch (error: any) {
      console.log(error.response)
    }
  }

  const formik = useFormik({
    initialValues: initialValues as FormikValues,
    validationSchema: validationOwnerSchema,
    onSubmit: handleSubmit,
  })

  return (
    <Box component='form' onSubmit={formik.handleSubmit} mt={2} noValidate>
      {activeAlert && (
        <Alert severity='error' sx={{marginTop: 2, marginBottom: 2}}>
          <Typography sx={{fontSize: '16px'}}>¡Carga algún archivo!</Typography>
        </Alert>
      )}
      <div
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >
        {/* begin::Aside*/}
        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
          {/* begin::Wrapper*/}
          <div className='card-body px-6 px-lg-10 px-xxl-15 py-10'>
            <label htmlFor='builderName' className='form-label fs-6 fw-bolder mb-3'>
              Seleccione una imagen
            </label>
            <input
              ref={inputFileRef}
              type='file'
              onChange={handleImageChange}
              style={{display: 'none'}}
              accept={'image/*'}
            />

            {imageUrl ? (
              <Box
                mt={2}
                sx={{padding: 2, borderRadius: '5px', position: 'relative'}}
                className={classes.hover}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                {activeButtons && (
                  <Box sx={{position: 'absolute', top: 1, right: 1}}>
                    <Stack>
                      <Tooltip title='Subir imagenes' onClick={handleImageUpload}>
                        <IconButton>
                          <AddPhotoAlternate />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Restablecer' onClick={handleResetUrlImage}>
                        <IconButton>
                          <SettingsBackupRestore />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Box>
                )}

                <Grid container rowSpacing={3}>
                  <Grid item xs={2}>
                    <Avatar
                      alt='images'
                      src={typeof imageUrl === 'string' ? imageUrl : ''}
                      sx={{width: 165, height: 165}}
                    />
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Fab variant='extended' onClick={handleImageUpload}>
                <AddPhotoAlternate sx={{mr: 2}} fontSize='large' />
                <Typography>Seleccionar</Typography>
              </Fab>
            )}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}

        <div className='card-body px-6 px-lg-10 px-xxl-15 py-10 bg-body'>
          <Grid container rowSpacing={{xs: 2, sm: 2, md: 2}} columnSpacing={{xs: 2, sm: 2, md: 2}}>
            <Grid item xs={7}>
              <Input field='builderName' label='Nombre' formik={formik} />
            </Grid>
            <Grid item xs={5}>
              <Input field='nit' label='NIT' formik={formik} />
            </Grid>
            <Grid item xs={6}>
              <Input
                field='epaycoAPiKey'
                label='Pasarela de pago (ePayco) llave pública'
                formik={formik}
                required={false}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                field='epaycoPrivateKey'
                label='Pasarela de pago (ePayco) llave privada'
                formik={formik}
                required={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                field='socialReason'
                label='Razón social'
                formik={formik}
                multiline={true}
                rows={5}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <Box sx={{display: 'flex', justifyContent: 'end', marginTop: 5}}>
        <button type='submit' className={`btn btn-primary`} disabled={isLoadingImage}>
          <Typography sx={{fontSize: '16px'}}>
            {isLoadingImage ? 'Cargando imagen...' : 'Siguiente'}
          </Typography>
        </button>
      </Box>
    </Box>
  )
}
