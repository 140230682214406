import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ListInvestments} from './InvestmentsList'
import {TransactionsList} from './TransactionsList'

const companyBreadCrumbs: Array<PageLink> = [
  {
    title: 'Gestionar',
    path: '/investments/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const InvestmentPage = () => (
  <Routes>
    <Route
      element={
        <>
          {/* <ProfileHeader /> */}
          <Outlet />
        </>
      }
    >
      <Route
        path='list'
        element={
          <>
            <PageTitle breadcrumbs={companyBreadCrumbs}>Inversiones</PageTitle>
            {/* <Campaigns /> */}
            <ListInvestments />
            <TransactionsList />
          </>
        }
      />
      <Route index element={<Navigate to='/builders' />} />
    </Route>
  </Routes>
)

export default InvestmentPage
