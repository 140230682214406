import {
  AuthBackendModel,
  AuthFrontendModel,
  AuthUserBackendModel,
  AuthUserFrontendModel,
} from './_models';

export const AuthAdapter = (auth: AuthBackendModel): AuthFrontendModel => {
  return {
    token: auth.token,
    refreshToken: auth.refreshToken,
    user: UserAdapter(auth.user),
  };
};

export const UserAdapter = (user: AuthUserBackendModel): AuthUserFrontendModel => {
  return {
    id: user.id,
    email: user.email,
  };
};

export const ErrorAuthAdapter = (error: string): string => {
  return error === 'Password is incorrect'
    ? 'Contraseña incorrecta'
    : error === 'Email not found'
    ? 'Email no encontrado'
    : 'Error desconocido';
};
