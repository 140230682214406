import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as authHelper from './AuthHelpers'
import {AuthFrontendModel, AuthUserFrontendModel} from './_models'

type AuthContextProps = {
  auth: AuthFrontendModel | undefined
  saveAuth: (auth: AuthFrontendModel | undefined) => void
  currentUser: AuthUserFrontendModel | undefined
  setCurrentUser: Dispatch<SetStateAction<AuthUserFrontendModel | undefined>>
  logout: () => void
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthFrontendModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<AuthUserFrontendModel | undefined>()
  const saveAuth = (auth: AuthFrontendModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  useEffect(() => {
    setCurrentUser(authHelper.getAuth()?.user)
  }, [])

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {logout} = useAuth()
  const auth = authHelper.getAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    if (!auth) {
      logout()
      setShowSplashScreen(false)
    } else {
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
