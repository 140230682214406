import {Box, Modal} from '@mui/material'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
}

interface Props {
  open: boolean
  handleClose: () => void
  extension: string | undefined
  url: string | undefined
}

export function ViewFileModal({open, handleClose, url}: Props) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box>
            <Box
              component='img'
              src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${url}`}
              alt='Documento'
              sx={{maxWidth: '1130px', maxHeight: '730px'}}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
