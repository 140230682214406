import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />

      <MenuInnerWithSub
        title='Inmuebles'
        to='/properties'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/properties/create' title='Crear' hasBullet={true} />
        <MenuItem to='/properties/list' title='Listar' hasBullet={true} />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title='Inversionistas'
        to='/investors'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/investors/list' title='Listar' hasBullet={true} />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title='Transacciones'
        to='/investments'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/investments/list' title='Listar' hasBullet={true} />
      </MenuInnerWithSub>
    </>
  )
}
