import {FC, useContext, useEffect} from 'react'
import {createContext, useState} from 'react'
import {Client, Socket} from 'sails.io.js'
import {WithChildren} from '../../../_metronic/helpers'
import {useMultipleGetDataHook} from '../hooks/useMultipleGetDataHook'
import {UserContext} from './UserProvider'

type SocketContextProps = {
  socket: Socket | null
  investors: [] | never[]
  properties: [] | never[]
  setInvestors: React.Dispatch<React.SetStateAction<never[]>>
  propertyId: string | undefined
  setPropertyId: React.Dispatch<React.SetStateAction<undefined | string>>
}

const API_URL = process.env.REACT_APP_SERVER_BASE_URL
const SocketContext = createContext<SocketContextProps>({} as SocketContextProps)

const SocketProvider: FC<WithChildren> = ({children}) => {
  const [socket, setSocket] = useState<Socket | null>(null)

  const [investors, setInvestors] = useState([])
  const [properties, setProperties] = useState([])
  const [propertyId, setPropertyId] = useState<undefined | string>()
  const {setInvestor} = useContext(UserContext)
  const {getOneInvestorData} = useMultipleGetDataHook()

  const appendSocketScript = () => {
    const script = document.createElement('script')
    script.src = `${API_URL}/dependencies/sails.io.js`
    script.onload = () => {
      ;(io as unknown as Client).sails.url = 'https://api.tucasapropia.com'
      setSocket((io as unknown as Client).socket)
    }
    document.body.append(script)
  }

  useEffect(() => {
    appendSocketScript()
  }, [])

  useEffect(() => {
    socket?.on('investors', () => {
      socket.get('/investors', async (data: any) => {
        const res = await getOneInvestorData(
          data.find((investor: any) => investor.id === investor?.id)
        )
        setInvestor(res)
        setInvestors(data)
      })
    })

    socket?.on('properties', () => {
      socket.get('/properties', async (data: any) => {
        setProperties(data)
      })
    })

    socket?.get('/investors', (data: any) => {
      setInvestors(data)
    })

    socket?.get('/properties', (data: any) => {
      setProperties(data)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  return (
    <SocketContext.Provider
      value={{socket, investors, properties, setInvestors, propertyId, setPropertyId}}
    >
      {children}
    </SocketContext.Provider>
  )
}
export {SocketContext, SocketProvider}
