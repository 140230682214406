/* eslint-disable react/jsx-no-target-blank */
import {Box} from '@mui/material'
import {useState} from 'react'
import {ViewFileModal} from './ViewFileModal'
interface Props {
  extension: string | undefined
  url: string | undefined
}
export default function File({extension, url}: Props) {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  return (
    <>
      {extension !== 'pdf' ? (
        <Box
          component='img'
          onClick={handleOpen}
          src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${url}`}
          alt='Documento'
          sx={{maxWidth: '400px', maxHeight: '300px', cursor: 'pointer'}}
        />
      ) : (
        <div>
          <Box
            component='iframe'
            sx={{width: '400px', height: '300px'}}
            src={`${process.env.REACT_APP_SERVER_BASE_URL}/files/${url}`}
          >
            <p>
              No se puede visualizar el archivo PDF. Visualice el archivo{' '}
              <a href={`${process.env.REACT_APP_SERVER_BASE_URL}/files/${url}`} target='_blank'>
                aquí
              </a>
              .
            </p>
          </Box>
        </div>
      )}
      <ViewFileModal open={open} handleClose={handleClose} extension={extension} url={url} />
    </>
  )
}
