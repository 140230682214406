import React, {useContext, useEffect} from 'react'
import {Stepper, Step, StepLabel, StepContent, Typography, Box} from '@mui/material'
import BasicInformationForm from './steps/BasicInformationForm'
import AdditionalInformationForm from './steps/AdditionalInformationForm'
import FilesForm from './steps/FilesForm'
import PropertyMap from './steps/PropertyMap'
import {UserContext} from '../context/UserProvider'
import {useParams} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import OwnerForm from './steps/OwnerForm'
import {updatePropertyAdapter} from './adapters/updatePropertyAdapter'
import axios from 'axios'

const API_URL = process.env.REACT_APP_SERVER_BASE_URL

const UpdateProperty = () => {
  const [activeStep, setActiveStep] = React.useState(-1)
  const {setProperty} = useContext(UserContext)
  const {id} = useParams()

  const handleFirstStep = () => {
    if (activeStep === -1) {
      handleNext()
    }
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const getPropertyData = async () => {
    const res = await axios.get(`${API_URL}/properties/${id}`)
    setProperty(updatePropertyAdapter(res.data))
  }
  useEffect(() => {
    getPropertyData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box
        className='cursor-pointer bg-body'
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: '5px',
          textAlign: 'center',
        }}
      >
        <h3 className='m-0'>Actualizar inmueble</h3>
      </Box>

      <Box mt={8}>
        <Stepper activeStep={activeStep} orientation='vertical'>
          <Step>
            <StepLabel onClick={handleFirstStep}>
              <label htmlFor='ownerName' className='form-label fs-3 mb-0'>
                Información propietario
              </label>
            </StepLabel>
            <StepContent>
              <OwnerForm handleNext={handleNext} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Typography sx={{fontSize: '18px'}}>Información básica</Typography>
            </StepLabel>
            <StepContent>
              <BasicInformationForm handleNext={handleNext} handleBack={handleBack} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Typography sx={{fontSize: '18px'}}>Información adicional</Typography>
            </StepLabel>
            <StepContent>
              <AdditionalInformationForm handleNext={handleNext} handleBack={handleBack} id={id} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Typography sx={{fontSize: '18px'}}>Multimedia</Typography>
            </StepLabel>
            <StepContent>
              <FilesForm handleNext={handleNext} handleBack={handleBack} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Typography sx={{fontSize: '18px'}}>Ubicación</Typography>
            </StepLabel>
            <StepContent>
              <PropertyMap
                handleNext={handleNext}
                handleBack={handleBack}
                id={id}
                setActiveStep={setActiveStep}
              />
            </StepContent>
          </Step>
        </Stepper>
      </Box>
      <ToastContainer />
    </>
  )
}

export default UpdateProperty
