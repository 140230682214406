import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthAdapter } from './AuthAdapters';
import { AuthBackendModel, AuthFrontendModel } from './_models';

const API_URL = process.env.REACT_APP_SERVER_BASE_URL;
const AUTH_LOCAL_STORAGE_KEY = 'bricks_auth';

const getAuth = (): AuthFrontendModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);

  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthFrontendModel = JSON.parse(lsValue) as AuthFrontendModel;
    if (auth) {
      return auth;
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
  }
};

const setAuth = (auth: AuthFrontendModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
  }
};

export const authInterceptor = () => {
  axios.interceptors.request.use((request: AxiosRequestConfig) => {
    const auth = getAuth();
    
    if (request.url?.includes(API_URL || ' ') && auth) {

      if (request.url?.includes('refresh-token')) {
        return request;
      }
      
      if (request.headers) request.headers.Authorization = `Bearer ${auth.token}`;
    }

    return request;
  });
};

export const authRefreshInterceptor = () => {
  const refreshToken = async () => {
    const auth = getAuth();

    if (auth) {
      try {
        const res = await axios.post<AuthBackendModel>(`${API_URL}/refresh-token`, {
          refreshToken: auth.refreshToken,
        });
        
        if (res.status === 200) {
          return res.data;
        } else {
          //removeAuth();
        }
      } catch (error) {
        console.error('REFRESH TOKEN ERROR', error);
        //removeAuth();
      }
    }
  };

  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config;

      if (error.response) {
        if (error.config.url?.includes(API_URL || ' ')) {
          if (!error.config.url?.includes('refresh-token')) {
            if (error.response.status === 403) {
              const auth = await refreshToken();

              if (auth) {
                setAuth(AuthAdapter(auth));
                if (originalRequest.headers) originalRequest.headers.Authorization = `Bearer ${auth.token}`;

                return axios(originalRequest);
              }
            }
          }
        }
      }
      return Promise.reject(error);
    }
  );
};

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
