import {Typography, Box, Alert, Chip} from '@mui/material'
import {useContext, useState} from 'react'
import {UserContext} from '../../context/UserProvider'
import {ImageUploader} from './uploaders/ImageUploader'
import {Input} from '../components/Input'
import {FormikValues, useFormik} from 'formik'
import {validationFilesSchema} from '../schemas/PropertySchema'

interface Props {
  handleNext: () => void
  handleBack: () => void
}

export default function FilesForm({handleNext, handleBack}: Props) {
  const {property, setProperty} = useContext(UserContext)
  const [activeAlert, setActiveAlert] = useState(false)
  const [imageUrls, setImageUrls] = useState<any[]>([])
  const [isLoadingImages, setIsLoadingImages] = useState(false)

  const initialValues = {
    video: property.video,
  }

  const formik = useFormik({
    initialValues: initialValues as FormikValues,
    validationSchema: validationFilesSchema,
    onSubmit: async (values) => {
      if (
        property.photos.length === 0 ||
        property.photos.length < 6 ||
        property.photos.length > 15
      ) {
        setActiveAlert(true)
        setTimeout(() => {
          setActiveAlert(false)
        }, 3000)
        return
      }
      let i: number = 0
      Object.keys(values).forEach((property) => {
        handleChangeProperty(property, Object.values(values)[i])
        i++
      })
      handleNext()
    },
  })

  const handleChangeProperty = (key: string, value: string) => {
    setProperty((prevState: any) => ({
      ...prevState,
      [key]: value,
    }))
  }

  return (
    <Box component='form' noValidate mt={2} onSubmit={formik.handleSubmit}>
      {activeAlert && (
        <Alert severity='error' sx={{marginTop: 2, marginBottom: 2}}>
          <Typography sx={{fontSize: '16px'}}>¡Carga todos los archivos!</Typography>
        </Alert>
      )}
      <div
        className='stepper stepper-pills stepper-column flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >
        {/* begin::Aside*/}
        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 mb-6 me-9'>
          {/* begin::Wrapper*/}
          <div className='card-body px-6 px-lg-10 px-xxl-15 py-10'>
            <Typography component='h3' variant='h6'>
              Cargar imagenes <strong className='fs-6'>(mínimo 6 y máximo 15)</strong>
            </Typography>
            <ImageUploader
              imageUrls={imageUrls}
              setImageUrls={setImageUrls}
              setProperty={setProperty}
              property={property}
              setIsLoadingImages={setIsLoadingImages}
            />
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}
        <div className='card-body px-6 px-lg-10 px-xxl-15 py-10 bg-body'>
          <Typography component='h3' variant='h6' className='mb-7'>
            Cargar video <strong className='fs-6'>(Solamente se admiten URL's de YouTube)</strong>
            {property.video && property.video.length !== 0 && (
              <Chip
                sx={{marginLeft: 2}}
                label='Cambiar video'
                color='error'
                size='small'
                onDelete={() => {
                  setProperty((prevState: any) => ({
                    ...prevState,
                    video: '',
                  }))
                }}
              />
            )}
          </Typography>
          {property.video && property.video.length !== 0 ? (
            <Box className='text-center'>
              <iframe
                title='YouTube video player'
                width='560'
                height='320'
                src={`https://www.youtube.com/embed/${property?.video?.match(/v=([^&]+)/)?.[1]}`}
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </Box>
          ) : (
            <Input label='Video URL' field='video' formik={formik} />
          )}
        </div>
      </div>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Box sx={{display: 'flex', justifyContent: 'end', marginTop: 5}}>
          <span className={`btn btn-primary`} onClick={handleBack}>
            <Typography sx={{fontSize: '16px'}}>Anterior</Typography>
          </span>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'end', marginTop: 5}}>
          <button type='submit' className={`btn btn-primary`} disabled={isLoadingImages}>
            <Typography sx={{fontSize: '16px'}}>
              {isLoadingImages ? 'Cargando imagenes...' : 'Siguiente'}{' '}
            </Typography>
          </button>
        </Box>
      </Box>
    </Box>
  )
}
