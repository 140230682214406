/* eslint-disable react-hooks/exhaustive-deps */
import {RemoveRedEye} from '@mui/icons-material'
import {
  Box,
  Chip,
  IconButton,
  Stack,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from '@mui/material'
import {grey} from '@mui/material/colors'
import MUIDataTable from 'mui-datatables'
import {useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import {alertSuccess} from '../../../_metronic/helpers/alerts'
import {useThemeMode} from '../../../_metronic/partials'
import {SocketContext} from '../context/SocketContext'
import {UserContext} from '../context/UserProvider'
import {useMultipleGetDataHook} from '../hooks/useMultipleGetDataHook'
const DataTable = MUIDataTable as any

export function ListInvestors() {
  const {investors, setInvestors, socket, propertyId, setPropertyId} = useContext(SocketContext)
  const {setInvestor} = useContext(UserContext)
  const [dataTable, setDataTable] = useState<any[]>([])
  const [action, setAction] = useState<string | null>(null)
  const {getMultipleInvestorsData} = useMultipleGetDataHook()

  const navigate = useNavigate()
  const {mode} = useThemeMode()

  const columns = [
    {
      name: 'id',
      options: {
        customBodyRender: (value: string) => {
          return <Typography sx={{paddingLeft: '28px'}}>{value}</Typography>
        },
        display: false,
      },
    },
    {
      name: 'user',
      label: 'Nombre',
      options: {
        customBodyRender: (value: any) => {
          return value?.data?.name?.first ? `${value?.data?.name?.first}` : 'Sin nombre'
        },
      },
    },
    {
      name: 'user',
      label: 'Apellidos',
      options: {
        customBodyRender: (value: any) => {
          return value?.data?.name?.last ? `${value?.data?.name?.last}` : 'Sin apellido'
        },
      },
    },
    {
      name: 'user',
      label: 'Teléfono',
      options: {
        customBodyRender: (value: any) => {
          return value?.data?.phone ? `${value?.data?.phone}` : 'Sin teléfono'
        },
      },
    },
    {
      name: 'user',
      label: 'Correo electrónico',
      options: {
        customBodyRender: (value: any) => {
          return value?.data?.email === null ? 'Sin email' : `${value?.data?.email}`
        },
      },
    },
    {
      name: 'status',
      label: 'Estado',
      options: {
        customBodyRender: (value: boolean) => {
          return (
            <Chip
              label={value ? 'Verificado' : 'Pendiente'}
              color={value ? 'success' : 'warning'}
              size='small'
              sx={{width: '70px'}}
            />
          )
        },
      },
    },
    {
      name: 'Acciones',
      options: {
        customBodyRenderLite: () => {
          return (
            <>
              <Stack direction='row' spacing={1}>
                <Tooltip title='Ver detalles'>
                  <IconButton color='primary' onClick={() => setAction('detail')}>
                    <RemoveRedEye />
                  </IconButton>
                </Tooltip>
              </Stack>
            </>
          )
        },
      },
    },
  ]

  const options = {
    filterType: 'multiselect',
    downloadOptions: {
      filename: 'ListaInversionistas.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    print: 'false',
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    selectableRows: 'none',
    textLabels: {
      pagination: {
        next: 'Siguiente página',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'REINICIAR',
      },
      viewColumns: {
        title: 'Ver columnas',
        titleAria: 'Ver/Ocultar Columnas de la tabla',
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas',
      },
    },
    onRowClick: (rowData: any) => {
      if (rowData) {
        if (typeof rowData[0] === 'object') {
          setPropertyId(rowData[0].props.children)
          setInvestor(dataTable.find((investor) => investor.id === rowData[0].props.children))
        }
      }
    },
  }

  const darkTheme = createTheme({
    palette: {
      mode: mode === 'system' ? 'dark' : mode,
      ...(mode === 'dark' && {
        primary: grey,
        divider: '#2b2b40',
        background: {
          default: '#2b2b40',
          paper: '#2b2b40',
        },
        text: {
          primary: '#fff',
          secondary: '#2b2b40',
        },
      }),
    },
  })

  const handleVerify = () => {
    try {
      socket?.put(`/investors/${propertyId}`, {status: true}, (data: any) => {
        socket?.get('/investors', (data: any) => {
          setInvestors(data)
        })
        alertSuccess('El usuario ha sido verificado')
      })
    } catch (error: any) {
      console.log(error.response)
    }
  }

  const handleDataTable = async () => {
    const res = await getMultipleInvestorsData(investors)
    setDataTable(res)
  }

  useEffect(() => {
    if (propertyId) {
      switch (action) {
        case 'detail':
          navigate(`/investors/detail/${propertyId}`)
          break
        case 'verifyTrue':
          handleVerify()
          break
      }
    }
  }, [propertyId, action])

  useEffect(() => {
    handleDataTable()
  }, [investors])

  useEffect(() => {
    if (dataTable.length !== 0) {
      setInvestor(dataTable.find((investor) => investor.id === propertyId))
    }
  }, [dataTable])

  return (
    <>
      <Box
        className='cursor-pointer bg-body'
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: '5px',
          textAlign: 'center',
        }}
        mb={2}
      >
        <h3 className='m-0'>Inversionistas</h3>
      </Box>
      <ThemeProvider theme={darkTheme}>
        {investors.length !== 0 && (
          <DataTable
            title={'Lista de inversionistas'}
            data={dataTable}
            columns={columns}
            options={options}
          />
        )}
      </ThemeProvider>
      <ToastContainer />
    </>
  )
}
