import {Box, Typography, Modal, Button, Stack} from '@mui/material'
import axios from 'axios'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

interface Props {
  open: boolean
  propertyId: string | null
  handleOpen: () => void
  handleClose: () => void
  handleAlert: () => void
  properties: any
  setProperties: any
}

const API_URL = process.env.REACT_APP_SERVER_BASE_URL

export default function DeleteProperty({
  open,
  handleClose,
  handleAlert,
  propertyId,
  properties,
  setProperties,
}: Props) {
  const handleDelete = async () => {
    await axios.delete(`${API_URL}/properties/${propertyId}`)
    setProperties(properties.filter((property: any) => property.id !== propertyId))
    handleAlert()
    handleClose()
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h5' component='h3'>
            Eliminar inmueble
          </Typography>
          <Typography id='modal-modal-description' sx={{mt: 2}} variant='body1'>
            ¿Esta seguro de eliminar este inmueble?
          </Typography>
          <Stack spacing={3} direction='row' mt={2}>
            <Button
              variant='contained'
              color='error'
              sx={{fontSize: '12px'}}
              onClick={handleDelete}
            >
              Eliminar
            </Button>
            <Button variant='contained' color='info' sx={{fontSize: '12px'}} onClick={handleClose}>
              Cancelar
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  )
}
