import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import {Select} from '@mui/material'
import {useFormik} from 'formik'
import {ResponsePropertyTypeModel} from '../models/PropertyTypeModel'

interface Props {
  field: string
  label: string
  formik: ReturnType<typeof useFormik>
  data?: ResponsePropertyTypeModel
}

export function Selectable({field, label, formik, data}: Props) {
  return (
    <FormControl sx={{minWidth: '100%'}}>
      <InputLabel id={`demo-simple-select-helper-${label}`}>{label}</InputLabel>
      <Select
        labelId={`demo-simple-select-helper-${label}`}
        label={label}
        error={formik.touched[field] && formik.errors[field] ? true : false}
        {...formik.getFieldProps(field)}
      >
        {data?.data.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {/*  */}
      {formik.touched[field] && formik.errors[field] && (
        <FormHelperText error>Seleccione una opción</FormHelperText>
      )}
    </FormControl>
  )
}
