import axios from 'axios';
import { AuthBackendModel } from './_models';

const API_URL = process.env.REACT_APP_SERVER_BASE_URL;

export const LOGIN_URL = `${API_URL}/login`;

// Server should return AuthModel
export const login = (email: string, password: string) => {
  return axios.post<AuthBackendModel>(LOGIN_URL, {
    email,
    password,
  });
};
