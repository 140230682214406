import axios from 'axios'
import {TextField} from '@mui/material'
import {FormikValues, useFormik} from 'formik'
import {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import {alertError, alertSuccess} from '../../../_metronic/helpers/alerts'
import {AutoComplete} from './components/AutoComplete'
import {InputPrice} from './components/InputPrice'
import {InvestorsBackendModel} from './models/InvestmentModel'
import {validationInvestmentSchema} from './schemas/PropertySchema'
import {SocketContext} from '../context/SocketContext'

interface Property {
  minimumAmount: string
  requiredAmmount: string
  maximumAmount: string
}

const API_URL = process.env.REACT_APP_SERVER_BASE_URL

export function Investment() {
  const [investors, setInvestors] = useState<InvestorsBackendModel[]>()
  const {properties} = useContext(SocketContext)
  const [property, setProperty] = useState<Property>()
  const {id} = useParams()
  const initialValues = {
    propertyId: id,
    investorId: '',
    holdingValue: 0,
  }

  const formik = useFormik({
    initialValues: initialValues as FormikValues,
    validationSchema: validationInvestmentSchema,
    onSubmit: async (values, {resetForm}) => {
      try {
        await axios.post(`${API_URL}/holdings`, values)
        alertSuccess('Inversión creada correctamente')
        resetForm()
      } catch (error) {
        alertError('¡Ha ocurrido un error!', 3500)
      }
    },
  })

  const getInvestors = async () => {
    const res = await axios.get<InvestorsBackendModel[]>(`${API_URL}/investors`)
    setInvestors(res.data)
  }

  const getProperty = async () => {
    const property: any = properties.find((property: any) => property.id === id)
    console.log(properties)
    console.log(property)

    setProperty({
      minimumAmount: convertTo(property?.minimumAmount),
      requiredAmmount: convertTo(property?.requiredAmmount),
      maximumAmount: convertTo(property?.maximumAmount),
    })
  }

  function convertTo(number: number) {
    return new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
    }).format(number)
  }

  useEffect(() => {
    getProperty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties])

  useEffect(() => {
    getInvestors()
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Realizar inversión</h3>
        </div>
      </div>
      <div className='card-body border-top p-9'>
        <div className='d-flex flex-wrap align-items-center'>
          <div id='kt_signin_email_edit' className={'flex-row-fluid '}>
            <form
              onSubmit={formik.handleSubmit}
              id='kt_signin_change_email'
              className='form'
              noValidate
            >
              <div className='row mb-6'>
                <div className='col-lg-4 mb-4 mb-lg-0 '>
                  <label htmlFor='investor' className='form-label fs-6 fw-bolder mb-5'>
                    Monto mínimo de inversión
                  </label>
                  <TextField label={property?.minimumAmount} className='form-control' disabled />
                </div>
                <div className='col-lg-4 mb-4 mb-lg-0 '>
                  <label htmlFor='investor' className='form-label fs-6 fw-bolder mb-5'>
                    Monto máximo para invertir
                  </label>
                  <TextField label={property?.maximumAmount} className='form-control' disabled />
                </div>
                <div className='col-lg-4 mb-4 mb-lg-0'>
                  <label htmlFor='investor' className='form-label fs-6 fw-bolder mb-5'>
                    Monto requerido
                  </label>
                  <TextField className='form-control' label={property?.requiredAmmount} disabled />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='card-body border-top p-9'>
        <div className='d-flex flex-wrap align-items-center'>
          <div id='kt_signin_email_edit' className={'flex-row-fluid '}>
            <form
              onSubmit={formik.handleSubmit}
              id='kt_signin_change_email'
              className='form'
              noValidate
            >
              <div className='row mb-6'>
                <div className='col-lg-6 mb-4 mb-lg-0 '>
                  <label htmlFor='investor' className='form-label fs-6 fw-bolder mb-5'>
                    Número de teléfono
                  </label>
                  {investors && (
                    <AutoComplete
                      id='phoneNumber'
                      label='Número telefónico'
                      field='investorId'
                      formik={formik}
                      data={investors}
                    />
                  )}
                </div>
                <div className='col-lg-6 mb-4 mb-lg-0 '>
                  <label htmlFor='investor' className='form-label fs-6 fw-bolder mb-5'>
                    Valor de inversión
                  </label>
                  <InputPrice field='holdingValue' label='Valor inversión' formik={formik} />
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-4 px-9'>
                <button type='submit' className='btn btn-primary'>
                  Guardar cambios
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
