import {Clear, Explore, Search} from '@mui/icons-material'
import {Divider, IconButton, InputBase, Paper} from '@mui/material'
import {Autocomplete} from '@react-google-maps/api'
import {useContext, useState} from 'react'
import {UserContext} from '../../context/UserProvider'

interface Props {
  setMarkerPosition: React.Dispatch<any>
}

export function InputMapSearch({setMarkerPosition}: Props) {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>()
  const [value, setValue] = useState('')
  const {setProperty} = useContext(UserContext)

  function onLoad(autocomplete: google.maps.places.Autocomplete) {
    setAutocomplete(autocomplete)
  }

  function onPlaceChanged() {
    console.log(autocomplete)
    if (autocomplete !== undefined) {
      setMarkerPosition({
        lat: autocomplete.getPlace().geometry?.location?.lat(),
        lng: autocomplete.getPlace().geometry?.location?.lng(),
      })
      setProperty((prevState: any) => ({
        ...prevState,
        location: [
          autocomplete.getPlace().geometry?.location?.lng(),
          autocomplete.getPlace().geometry?.location?.lat(),
        ],
      }))
    }
  }
  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} restrictions={{country: 'co'}}>
      <Paper
        component='form'
        sx={{
          position: 'absolute',
          top: 5,
          left: 5,
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: 400,
        }}
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
      >
        <IconButton sx={{p: '10px'}} aria-label='menu'>
          <Explore />
        </IconButton>
        <InputBase
          sx={{ml: 1, flex: 1}}
          placeholder='Buscar en Google Maps'
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <IconButton type='button' sx={{p: '10px'}} aria-label='search'>
          <Search />
        </IconButton>
        {value !== '' && (
          <>
            <Divider sx={{height: 28, m: 0.5}} orientation='vertical' />
            <IconButton
              color='primary'
              sx={{p: '10px'}}
              aria-label='directions'
              onClick={(e) => setValue('')}
            >
              <Clear />
            </IconButton>
          </>
        )}
      </Paper>
    </Autocomplete>
  )
}
