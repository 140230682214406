import 'react-toastify/dist/ReactToastify.css'
import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, authInterceptor, authRefreshInterceptor} from './modules/auth'
import {UserProvider} from './modules/context/UserProvider'
import {SocketProvider} from './modules/context/SocketContext'
import {createTheme, ThemeProvider} from '@mui/material'
import {useThemeMode} from '../_metronic/partials'
authInterceptor()
authRefreshInterceptor()
const App = () => {
  const {mode} = useThemeMode()
  const theme = createTheme({
    palette: {
      mode: mode === 'system' || mode === 'dark' ? 'dark' : 'light',
    },
  })

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <UserProvider>
            <SocketProvider>
              <ThemeProvider theme={theme}>
                <AuthInit>
                  <Outlet />
                  <MasterInit />
                </AuthInit>
              </ThemeProvider>
            </SocketProvider>
          </UserProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
