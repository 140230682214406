/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title='Home'
        fontIcon='bi-house fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        className='py-2'
      />

      <AsideMenuItemWithSubMain
        to='/properties'
        title='Inmuebles'
        fontIcon='bi-file-text'
        bsTitle='Inmuebles'
      >
        <AsideMenuItem
          to='/properties/create'
          title='Crear'
          bsTitle='Crear inmueble'
          hasBullet={true}
        />

        <AsideMenuItem
          to='/properties/list'
          title='Listar'
          bsTitle='Listar inmuebles'
          hasBullet={true}
        />
      </AsideMenuItemWithSubMain>

      <AsideMenuItemWithSubMain
        to='/investors'
        title='Inversionistas'
        fontIcon='bi-file-text'
        bsTitle='Inversionistas'
      >
        <AsideMenuItem
          to='/investors/list'
          title='Listar'
          bsTitle='Listar inversionistas'
          hasBullet={true}
        />
      </AsideMenuItemWithSubMain>

      <AsideMenuItemWithSubMain
        to='/investments'
        title='Transacciones'
        fontIcon='bi-file-text'
        bsTitle='Transacciones'
      >
        <AsideMenuItem
          to='/investments/list'
          title='Listar'
          bsTitle='Listar transacciones'
          hasBullet={true}
        />
      </AsideMenuItemWithSubMain>
    </>
  )
}
