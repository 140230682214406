import MUIDataTable from 'mui-datatables'
import {
  Typography,
  Box,
  LinearProgress,
  LinearProgressProps,
  Chip,
  Tooltip,
  IconButton,
  Stack,
} from '@mui/material'
import {Edit, AddAPhoto} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
//import {refreshToken} from '../../../auth'
const DataTable = MUIDataTable as any

export function TransactionsList() {
  const [propertyId, setPropertyId] = useState<{}>()
  const [action, setAction] = useState<string | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (propertyId) {
      switch (action) {
        case 'update':
          navigate(`/properties/update/${propertyId}`)
          break
        case 'gallery':
          navigate(`/properties/gallery/${propertyId}`)
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId, action])

  function LinearProgressWithLabel(props: LinearProgressProps & {value: number}) {
    return (
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box sx={{width: '100%', mr: 1}}>
          <LinearProgress variant='determinate' {...props} />
        </Box>
        <Box sx={{minWidth: 35}}>
          <Typography variant='body1' color='text.secondary'>{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  function setChipColor(value: string) {
    switch (value) {
      case 'Active':
        return 'primary'
      case 'Founded':
        return 'success'
      case 'Paid':
        return 'error'
      case 'Disabled':
        return 'secondary'
    }
  }

  const columns = [
    {
      name: 'id',
      label: '.',
      options: {
        customBodyRender: (value: string) => {
          return <Typography sx={{paddingLeft: '28px'}}>{value}</Typography>
        },
      },
    },
    {
      name: 'investor',
      label: '.',
    },
    {
      name: 'Participación',
      label: '.',
    },
    {
      name: 'Ladrillo en venta',
      label: '.',
      options: {
        customBodyRender: (value: string) => {
          return (
            <Chip label={value} color={setChipColor(value)} size='small' sx={{width: '70px'}} />
          )
        },
      },
    },
    {
      name: 'Cantidad',
      label: '.',
    },
    {
      name: 'Fecha',
      label: '.',
      options: {
        customBodyRender: (value: string) => {
          return <Typography sx={{paddingLeft: 7}}>{value}</Typography>
        },
      },
    },
    {
      name: 'Hora',
      label: '.',
      options: {
        customBodyRender: (value: string) => {
          return <LinearProgressWithLabel variant='determinate' value={parseInt(value)} />
        },
      },
    },
    {
      name: 'Estado',
      label: '.',
      options: {
        customBodyRenderLite: () => {
          return (
            <>
              <Stack direction='row' spacing={1}>
                <Tooltip title='Actualizar'>
                  <IconButton color='warning' onClick={() => setAction('update')}>
                    <Edit />
                  </IconButton>
                </Tooltip>

                <Tooltip title='Avance de obra'>
                  <IconButton color='success' onClick={() => setAction('gallery')}>
                    <AddAPhoto />
                  </IconButton>
                </Tooltip>
              </Stack>
            </>
          )
        },
        filter: false,
      },
    },
  ]

  const data = [
    [1, 'Conjunto el poblado', 'Bogotá', 'Active', 'Constructora Bolivar', '5', '45'],
    [2, 'John Walsh', 'Bogotá', 'Founded', 'Marval', '2', '90'],
    [3, 'Bob Herm', 'Bogotá', 'Active', 'Constructora Bolivar', '1', '75'],
    [4, 'James Houston', 'Bogotá', 'Founded', 'Marval', '2', '35'],
    [5, 'Joe James', 'Bogotá', 'Active', 'Ar Construcciones', '1', '35'],
    [6, 'John Walsh', 'Bogotá', 'Founded', 'Constructora Bolivar', '2', '35'],
    [7, 'Bob Herm', 'Bogotá', 'Active', 'Marval', '5', '35'],
    [8, 'James Houston', 'Bogotá', 'Founded', 'Ar Construcciones', '5', '35'],
    [9, 'Joe James', 'Bogotá', 'Paid', 'Marval', '5', '35'],
    [10, 'John Walsh', 'Bogotá', 'Active', 'Constructora Bolivar', '1', '35'],
    [11, 'Bob Herm', 'Bogotá', 'Active', 'Ar Construcciones', '2', '35'],
    [12, 'James Houston', 'Bogotá', 'Founded', 'Constructora Bolivar', '5', '35'],
    [13, 'Joe James', 'Bogotá', 'Paid', 'Marval', '5', '35'],
    [14, 'John Walsh', 'Bogotá', 'Active', 'Marval', '2', '35'],
    [15, 'Bob Herm', 'Bogotá', 'Founded', 'Ar Construcciones', '5', '35'],
    [16, 'James Houston', 'Bogotá', 'Active ', 'Constructora Bolivar', '1', '35'],
  ]

  const options = {
    filterType: 'multiselect',
    downloadOptions: {
      filename: 'ListaInmuebles.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    print: 'false',
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    selectableRows: 'none',
    textLabels: {
      pagination: {
        next: 'Siguiente página',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'REINICIAR',
      },
      viewColumns: {
        title: 'Ver columnas',
        titleAria: 'Ver/Ocultar Columnas de la tabla',
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas',
      },
    },
    onRowClick: (rowData: string[] | {props: {children: {}}}[]) => {
      if (rowData) {
        if (typeof rowData[0] === 'object') {
          setPropertyId(rowData[0].props.children)
        }
      }
    },
  }

  /*  useEffect(() => {
    refreshToken()
  }) */

  return (
    <>
      <Box
        className='cursor-pointer bg-body'
        sx={{
          marginTop: 5,
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: '5px',
        }}
        mb={2}
      >
        <h3 className='mx-5'>Transacciones de ePayco</h3>
      </Box>
      <DataTable title={'Transacciones'} data={data} columns={columns} options={options} />
    </>
  )
}
