import React, {useContext} from 'react'
import {Stepper, Step, StepLabel, StepContent, Box} from '@mui/material'
import BasicInformationForm from './steps/BasicInformationForm'
import AdditionalInformationForm from './steps/AdditionalInformationForm'
import FilesForm from './steps/FilesForm'
import PropertyMap from './steps/PropertyMap'
import {UserContext} from '../context/UserProvider'
import {ToastContainer} from 'react-toastify'
import OwnerForm from './steps/OwnerForm'

const CreateProperty = () => {
  const [activeStep, setActiveStep] = React.useState(-1)
  const {setProperty, initialState} = useContext(UserContext)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleFirstStep = () => {
    if (activeStep === -1) {
      setProperty(initialState)
      handleNext()
    }
  }

  return (
    <>
      <Box
        className='cursor-pointer bg-body'
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: '5px',
          textAlign: 'center',
        }}
      >
        <h3 className='m-0'>Crear inmueble</h3>
      </Box>

      <Box mt={8}>
        <Stepper activeStep={activeStep} orientation='vertical'>
          <Step>
            <StepLabel onClick={handleFirstStep}>
              <label htmlFor='builderName' className='form-label fs-3 mb-0 cursor-pointer'>
                Información propietario
              </label>
            </StepLabel>
            <StepContent>
              <OwnerForm handleNext={handleNext} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <label htmlFor='basicName' className='form-label fs-3 mb-0'>
                Información básica
              </label>
            </StepLabel>
            <StepContent>
              <BasicInformationForm handleNext={handleNext} handleBack={handleBack} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <label htmlFor='additionalName' className='form-label fs-3 mb-0'>
                Información adicional
              </label>
            </StepLabel>
            <StepContent>
              <AdditionalInformationForm
                handleNext={handleNext}
                handleBack={handleBack}
                id={undefined}
              />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <label htmlFor='builderName' className='form-label fs-3 mb-0'>
                Multimedia
              </label>
            </StepLabel>
            <StepContent>
              <FilesForm handleNext={handleNext} handleBack={handleBack} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <label htmlFor='locationName' className='form-label fs-3 mb-0'>
                Ubicación
              </label>
            </StepLabel>
            <StepContent>
              <PropertyMap
                handleNext={handleNext}
                handleBack={handleBack}
                id={undefined}
                setActiveStep={setActiveStep}
              />
            </StepContent>
          </Step>
        </Stepper>
      </Box>
      <ToastContainer />
    </>
  )
}

export default CreateProperty
