import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import {FormikProvider, FormikValues, useFormik} from 'formik'
import {useContext} from 'react'
import {UserContext} from '../../context/UserProvider'
import {CheckBox} from '../components/Checkbox'
import {Input} from '../components/Input'
import {InputPrice} from '../components/InputPrice'
import {validationAdditionalInformationSchema} from '../schemas/PropertySchema'

interface Props {
  handleNext: () => void
  handleBack: () => void
  id: string | undefined
}

export default function AdditionalInformationForm({handleNext, handleBack, id}: Props) {
  const {property, setProperty} = useContext(UserContext)

  const initialValues = {
    requiredAmmount: property.requiredAmmount,
    minimumAmount: property.minimumAmount,
    estimatedProfitability: property.estimatedProfitability,
    estimatedTerm: property.estimatedTerm,
    status: property.status,
    termToInvest: property.termToInvest,
    propertySecurity: property.propertySecurity,
    propertySetFeatures: property.propertySetFeatures,
    propertyNearby: property.propertyNearby,
    completionPercentage: property.completionPercentage,
  }

  const handleChangeProperty = (key: string, value: string) => {
    setProperty((prevState: any) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const formik = useFormik({
    initialValues: initialValues as FormikValues,
    validationSchema: validationAdditionalInformationSchema,
    onSubmit: (values) => {
      let i: number = 0
      Object.keys(values).forEach((property) => {
        handleChangeProperty(property, Object.values(values)[i])
        i++
      })
      handleNext()
    },
  })

  return (
    <Box mt={2}>
      <Box component='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_create_account_stepper'
        >
          <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
            <div className='card-body px-6 px-lg-10 px-xxl-15 py-10'>
              <Box>
                <label htmlFor='estimatedTermId' className='form-label fs-6 fw-bolder '>
                  Plazo en meses
                </label>

                <Input field='estimatedTerm' formik={formik} label='Estimación' type='number' />
              </Box>

              <Box mt={2}>
                <label htmlFor='estimatedTermId' className='form-label fs-6 fw-bolder'>
                  Fecha máxima para invertir
                </label>

                <Input field='termToInvest' formik={formik} type='date' />
              </Box>
            </div>
          </div>

          <div className='card-body px-6 px-lg-10 px-xxl-15 py-10 bg-body'>
            <Box>
              <Grid
                container
                columnSpacing={{xs: 1, sm: 2, md: 3}}
                mt={2}
                rowSpacing={{xs: 2, sm: 2, md: 2}}
              >
                <Grid item xs={6}>
                  <InputPrice field='requiredAmmount' label='Monto requerido' formik={formik} />
                </Grid>

                <Grid item xs={6}>
                  <InputPrice
                    field='minimumAmount'
                    label='Monto mínimo de inversión'
                    formik={formik}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Box>
                    <Input
                      field='estimatedProfitability'
                      label='Rentabilidad estimada'
                      formik={formik}
                    />
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box>
                    <Input
                      field='completionPercentage'
                      label='Porcentaje de avance de obra'
                      formik={formik}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id='stateLabel'>Estado</InputLabel>
                      <Select
                        labelId='statusLabel'
                        id='demo-simple-select'
                        label='Estado'
                        name='status'
                        disabled={id ? false : true}
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        error={formik.touched.status && formik.errors.status ? true : false}
                      >
                        <MenuItem value={''}>Seleccione una opción</MenuItem>
                        <MenuItem value={'actived'}>Activo</MenuItem>
                        <MenuItem value={'founded'}>Meta cumplida</MenuItem>
                        <MenuItem value={'paid'}>Pagado</MenuItem>
                        <MenuItem value={'soon'}>Próximamente</MenuItem>
                        <MenuItem value={'disabled'}>Desactivado</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
        <div className='card mb-5 mb-xl-10 mt-9'>
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_account_signin_method2'
          >
            <div className='card-title m-0'>
              <h5 className='fw-bolder m-0'>Seguridad </h5>
            </div>
          </div>

          <div id='kt_account_signin_method2' className='collapse show'>
            <div className='card-body border-top p-9'>
              <div className='d-flex flex-wrap align-items-center'>
                <div id='kt_signin_email_edit' className={'flex-row-fluid '}>
                  <div className='row'>
                    <div className='col-lg-12 mb-2 mb-lg-0'>
                      <div className='fv-row mb-0'>
                        <Stack direction='row' spacing={2}>
                          <FormikProvider value={formik}>
                            <CheckBox field='propertySecurity' value='Alarma' />
                            <CheckBox field='propertySecurity' value='Circuito cerrado de TV' />
                            <CheckBox field='propertySecurity' value='Portería privada' />
                          </FormikProvider>
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card mb-5 mb-xl-10 mt-9'>
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_account_signin_method3'
          >
            <div className='card-title m-0'>
              <h5 className='fw-bolder m-0'>Características conjunto </h5>
            </div>
          </div>

          <div id='kt_account_signin_method3' className='collapse show'>
            <div className='card-body border-top p-9'>
              <div className='d-flex flex-wrap align-items-center'>
                <div id='kt_signin_email_edit' className={'flex-row-fluid '}>
                  <div className='row'>
                    <div className='col-lg-12 mb-2 mb-lg-0'>
                      <div className='fv-row mb-0'>
                        <Stack direction='row' spacing={4}>
                          <FormikProvider value={formik}>
                            <CheckBox field='propertySetFeatures' value='Ascensor' />
                            <CheckBox field='propertySetFeatures' value='Cancha de baloncesto' />
                            <CheckBox field='propertySetFeatures' value='Cancha de Squash' />
                            <CheckBox field='propertySetFeatures' value='Salón comunal' />
                            <CheckBox field='propertySetFeatures' value='Ruta para correr' />
                            <CheckBox field='propertySetFeatures' value='Gimnasio' />
                            <CheckBox field='propertySetFeatures' value='Piscina' />
                          </FormikProvider>
                        </Stack>
                        <Stack direction='row' spacing={3}>
                          <FormikProvider value={formik}>
                            <CheckBox field='propertySetFeatures' value='Zona BBQ' />
                            <CheckBox field='propertySetFeatures' value='Zona para mascotas' />
                            <CheckBox field='propertySetFeatures' value='Sauna' />
                          </FormikProvider>
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card mb-5 mb-xl-10 mt-9'>
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_account_signin_method4'
          >
            <div className='card-title m-0'>
              <h5 className='fw-bolder m-0'>Cerca de</h5>
            </div>
          </div>

          <div id='kt_account_signin_method4' className='collapse show'>
            <div className='card-body border-top p-9'>
              <div className='d-flex flex-wrap align-items-center'>
                <div id='kt_signin_email_edit' className={'flex-row-fluid '}>
                  <div className='row'>
                    <div className='col-lg-12 mb-2 mb-lg-0'>
                      <div className='fv-row mb-0'>
                        {
                          <Stack direction='row' spacing={4}>
                            <FormikProvider value={formik}>
                              <CheckBox field='propertyNearby' value='Centros comerciales' />
                              <CheckBox field='propertyNearby' value='Colegios' />
                              <CheckBox field='propertyNearby' value='Jardines' />
                              <CheckBox field='propertyNearby' value='Centros médicos' />
                              <CheckBox field='propertyNearby' value='Hospitales' />
                              <CheckBox field='propertyNearby' value='Supermercados' />
                              <CheckBox field='propertyNearby' value='Estación transmilenio' />
                            </FormikProvider>
                          </Stack>
                        }
                        <Stack direction='row' spacing={11} sx={{marginTop: 1}}>
                          <FormikProvider value={formik}>
                            <CheckBox field='propertyNearby' value='Peluquería' />
                            <CheckBox field='propertyNearby' value='Paradas Sitp' />
                            <CheckBox field='propertyNearby' value='Universidades' />
                          </FormikProvider>
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Box sx={{display: 'flex', justifyContent: 'end', marginTop: 5}}>
            <span className={`btn btn-primary`} onClick={handleBack}>
              <Typography sx={{fontSize: '16px'}}>Anterior</Typography>
            </span>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'end', marginTop: 5}}>
            <button type='submit' className={`btn btn-primary`}>
              <Typography sx={{fontSize: '16px'}}>Siguiente</Typography>
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
