/* eslint-disable @typescript-eslint/no-unused-vars */
import {Typography, Box, LinearProgress, LinearProgressProps, Chip} from '@mui/material'
import {useEffect, useState} from 'react'
import axios from 'axios'
import {MUITable} from '../components/MUITable'
import {useGetInvestors} from './hooks/useGetInvestors'
import {InvestmentBackendModel} from './models/InvestmentModel'

const API_URL = process.env.REACT_APP_SERVER_BASE_URL

export function ListInvestments() {
  const [investments, setInvestments] = useState<any>([])
  const [investmentId, setInvestmentId] = useState<string | null>(null)
  const {getMultipleInvestorsData} = useGetInvestors()

  function LinearProgressWithLabel(props: LinearProgressProps & {value: number}) {
    return (
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box sx={{width: '100%', mr: 1}}>
          <LinearProgress variant='determinate' {...props} />
        </Box>
        <Box sx={{minWidth: 35}}>
          <Typography variant='body1' color='text.secondary'>{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'investor',
      label: 'Inversionista',
      options: {
        customBodyRender: (value: any) => {
          return `${value.first} ${value.last}`
        },
      },
    },
    {
      name: 'property',
      label: 'Ladrillo',
      options: {
        customBodyRender: (value: any) => {
          return `${value.title}`
        },
      },
    },
    {
      name: 'forSale',
      label: 'En venta',
      options: {
        customBodyRender: (value: boolean) => {
          return (
            <Chip
              label={value ? 'Vendido' : 'Pendiente'}
              color={value ? 'success' : 'warning'}
              size='small'
              sx={{width: '70px'}}
            />
          )
        },
      },
    },
    {
      name: 'holdingPercentage',
      label: '% de inversión',
      options: {
        customBodyRender: (value: string) => {
          return <LinearProgressWithLabel variant='determinate' value={parseInt(value)} />
        },
      },
    },
    {
      name: 'isPaidOut',
      label: 'Pagado',
      options: {
        customBodyRender: (value: boolean) => {
          return (
            <Chip
              label={value ? 'Pagado' : 'No pago'}
              color={value ? 'success' : 'error'}
              size='small'
              sx={{width: '70px'}}
            />
          )
        },
      },
    },
    {
      name: 'createdBy',
      label: 'Creado por',
    },
  ]

  const getInvestmentsData = async () => {
    const res = await axios.get<InvestmentBackendModel[]>(`${API_URL}/holdings`)

    const properties = await getMultipleInvestorsData(res.data)
    setInvestments(properties)
  }

  useEffect(() => {
    getInvestmentsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box
        className='cursor-pointer bg-body'
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: '5px',
        }}
        mb={2}
      >
        <h3 className='mx-5'>Inversiones</h3>
      </Box>
      <MUITable title='Inversiones' columns={columns} data={investments} setId={setInvestmentId} />
    </>
  )
}
