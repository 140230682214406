import {useContext} from 'react'
import {UserContext} from '../../context/UserProvider'

export function Pep() {
  const {investor} = useContext(UserContext)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details5'
        aria-expanded='true'
        aria-controls='kt_account_profile_details5'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>PEP (Persona Públicamente expuesta)</h3>
        </div>
      </div>

      <div id='kt_account_profile_details5' className='collapse show'>
        <form noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                ¿Es una persona expuesta públicamente PEP?
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={investor?.isPEP}
                  disabled
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                ¿Maneja recursos públicos o provenientes de la contratación de entidades públicas?
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={investor?.publicResources}
                  disabled
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>
                  ¿Algún familiar desempeña o ha desempeñado un cargo público?
                </span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={investor?.familyPublicOffice}
                  disabled
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>
                  Nombre y parentesco del familiar que desempeña o ha desempeñado un cargo público
                </span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={investor?.nameAndRelationship}
                  disabled
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
