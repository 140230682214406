/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useState} from 'react'
import {FormikValues, useFormik} from 'formik'
import {validationBasicInformationSchema} from '../schemas/PropertySchema'
import {Grid, Box, Typography, Stack} from '@mui/material'
import {UserContext} from '../../context/UserProvider'
import axios from 'axios'
import {Input} from '../components/Input'
import {Selectable} from '../components/Selectable'
import {ResponsePropertyTypeModel} from '../models/PropertyTypeModel'

interface Props {
  handleNext: () => void
  handleBack: () => void
}

export default function BasicInformationForm({handleNext, handleBack}: Props) {
  const {property, setProperty} = useContext(UserContext)
  const [propertyTypes, setPropertyTypes] = useState<ResponsePropertyTypeModel>()

  const initialValues = {
    title: property.title,
    address: property.address,
    publicAddress: property.publicAddress,
    rooms: property.rooms,
    baths: property.baths,
    balconies: property.balconies,
    garages: property.garages,
    deposits: property.deposits,
    tower: property.tower,
    apartment: property.apartment,
    virtualTour: property.virtualTour,
    description: property.description,
    propertyType: property.propertyType,
    area: property.area,
  }

  const formik = useFormik({
    initialValues: initialValues as FormikValues,
    validationSchema: validationBasicInformationSchema,
    onSubmit: (values) => {
      let i: number = 0
      Object.keys(values).forEach((property) => {
        handleChangeProperty(property, Object.values(values)[i])
        i++
      })
      handleNext()
    },
  })

  const handleChangeProperty = (key: string, value: string) => {
    setProperty((prevState: any) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const getPropertyTypesData = async () => {
    const res = await axios.get<ResponsePropertyTypeModel>(
      'https://api.tucasapropia.com/tcpapi/propertytypes'
    )
    setPropertyTypes(res.data)
  }

  useEffect(() => {
    getPropertyTypesData()
  }, [])

  return (
    <Box component='form' onSubmit={formik.handleSubmit} mt={2} noValidate>
      <div
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >
        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
          <div className='card-body px-6 px-lg-10 px-xxl-15 py-10'>
            <Stack spacing={2}>
              <Input field='rooms' label='Habitaciones' formik={formik} type='number' />
              <Input field='baths' label='Baños' formik={formik} type='number' />
              <Input field='garages' label='Garajes' formik={formik} type='number' />
              <Input field='deposits' label='Depósitos' formik={formik} type='number' />
              <Input field='balconies' label='Balcones' formik={formik} type='number' />
            </Stack>
          </div>
        </div>

        <div className='card-body px-6 px-lg-10 px-xxl-15 py-10 bg-body'>
          <Box>
            <Grid
              container
              columnSpacing={{xs: 2, sm: 2, md: 2}}
              rowSpacing={{xs: 2, sm: 2, md: 2}}
            >
              <Grid item xs={8}>
                <Input field='title' label='Nombre' formik={formik} />
              </Grid>
              <Grid item xs={4}>
                <Input field='area' label='Área' formik={formik} type='number' />
              </Grid>
              <Grid item xs={7}>
                <Input field='tower' label='Torre' formik={formik} />
              </Grid>
              <Grid item xs={5}>
                <Input field='apartment' label='Apartamento' formik={formik} />
              </Grid>

              <Grid item xs={6}>
                <Input field='address' label='Dirección' formik={formik} />
              </Grid>

              <Grid item xs={6}>
                <Input field='publicAddress' label='Dirección pública' formik={formik} />
              </Grid>

              <Grid item xs={7}>
                <Input field='virtualTour' label='Tour virtual' formik={formik} required={false} />
              </Grid>

              <Grid item xs={5}>
                <Selectable
                  field='propertyType'
                  label='Tipo de propiedad'
                  formik={formik}
                  data={propertyTypes}
                />
              </Grid>

              <Grid item xs={12}>
                <Input field='description' label='Descripción' formik={formik} required={false} />
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
      <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: 5}}>
        <button className={`btn btn-primary`} type='button' onClick={handleBack}>
          <Typography sx={{fontSize: '16px'}}>Anterior</Typography>
        </button>
        <button type='submit' className={`btn btn-primary`}>
          <Typography sx={{fontSize: '16px'}}>Siguiente</Typography>
        </button>
      </Box>
    </Box>
  )
}
