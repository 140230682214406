import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import CreateProperty from './CreateProperty'
import {ListProperties} from './PropertiesList'
import UpdateProperty from './UpdateProperty'
import {Investment} from './Investment'
import {NavigationGallery} from '../gallery/NavigationGallery'

const companyBreadCrumbs: Array<PageLink> = [
  {
    title: 'Gestionar',
    path: '/properties/create',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PropertyPage = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path='create'
        element={
          <>
            <PageTitle breadcrumbs={companyBreadCrumbs}>Inmueble</PageTitle>
            {/* <Overview /> */}
            <CreateProperty />
          </>
        }
      />
      <Route
        path='update/:id'
        element={
          <>
            <PageTitle breadcrumbs={companyBreadCrumbs}>Inmueble</PageTitle>
            <UpdateProperty />
          </>
        }
      />
      <Route
        path='list'
        element={
          <>
            <PageTitle breadcrumbs={companyBreadCrumbs}>Inmueble</PageTitle>
            {/* <Campaigns /> */}
            <ListProperties />
          </>
        }
      />
      <Route
        path='gallery/:id'
        element={
          <>
            <PageTitle breadcrumbs={companyBreadCrumbs}>Avance de obra</PageTitle>
            <NavigationGallery />
          </>
        }
      />
      <Route
        path='investment/:id'
        element={
          <>
            <PageTitle breadcrumbs={companyBreadCrumbs}>Inversión</PageTitle>
            <Investment />
          </>
        }
      />
      <Route index element={<Navigate to='/builders' />} />
    </Route>
  </Routes>
)

export default PropertyPage
