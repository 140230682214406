import {useContext} from 'react'
import {UserContext} from '../../context/UserProvider'

export function ProfessionalInformation() {
  const {investor} = useContext(UserContext)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details3'
        aria-expanded='true'
        aria-controls='kt_account_profile_details3'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Información profesional</h3>
        </div>
      </div>

      <div id='kt_account_profile_details3' className='collapse show'>
        <form noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Ocupación</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={investor?.occupation}
                  disabled
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Profesión</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={investor?.profession}
                  disabled
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Empleador</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={investor?.employer}
                  disabled
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Nivel de estudios</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={investor?.educationalLevel}
                  disabled
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
