import axios from 'axios'
import {useState} from 'react'

interface StateValues {
  users: object[]
  birthDepartments: object[]
  birthCity: object[]
  residenceCountry: object[]
  residenceCity: object[]
  countryOriginResources: object[]
}

export function useMultipleGetDataHook() {
  const initialState: StateValues = {
    users: [],
    birthDepartments: [],
    birthCity: [],
    residenceCountry: [],
    residenceCity: [],
    countryOriginResources: [],
  }
  const [data, setData] = useState(initialState)

  async function getUser(id: string) {
    const user = data.users.find((user: any) => user.data.id === id)
    if (user) {
      return user
    } else {
      if (id) {
        const res = await axios.get(`https://api.tucasapropia.com/tcpapi/users/${id}`)
        setData((prevState: any) => ({
          ...prevState,
          users: [...prevState.users, res.data],
        }))
        return res.data
      }
    }
  }

  async function getBirthDepartment(id: string) {
    const user = data.birthDepartments.find((user: any) => user.data._id === id)
    if (user) {
      return user
    } else {
      if (id) {
        const res = await axios.get(`https://api.tucasapropia.com/tcplocation/states/${id}`)
        setData((prevState: any) => ({
          ...prevState,
          birthDepartments: [...prevState.birthDepartments, res.data],
        }))
        return res.data
      }
    }
  }

  async function getBirthCity(id: string) {
    const user = data.birthCity.find((user: any) => user._id === id)
    if (user) {
      return user
    } else {
      if (id) {
        const res = await axios.get(`https://api.tucasapropia.com/tcplocation/cities/${id}`)
        setData((prevState: any) => ({
          ...prevState,
          birthCity: [...prevState.birthCity, res.data],
        }))
        return res.data
      }
    }
  }

  async function getResidenceCountry(id: string) {
    const user = data.residenceCountry.find((user: any) => user._id === id)
    if (user) {
      return user
    } else {
      if (id) {
        const res = await axios.get(`https://api.tucasapropia.com/tcplocation/countries/${id}`)
        setData((prevState: any) => ({
          ...prevState,
          residenceCountry: [...prevState.residenceCountry, res.data],
        }))
        return res.data
      }
    }
  }

  async function getResidenceCity(id: string) {
    const user = data.residenceCity.find((user: any) => user._id === id)
    if (user) {
      return user
    } else {
      if (id) {
        const res = await axios.get(`https://api.tucasapropia.com/tcplocation/cities/${id}`)
        setData((prevState: any) => ({
          ...prevState,
          residenceCity: [...prevState.residenceCity, res.data],
        }))
        return res.data
      }
    }
  }

  async function getCountryOriginResources(id: string) {
    const user = data.countryOriginResources.find((user: any) => user._id === id)
    if (user) {
      return user
    } else {
      if (id) {
        const res = await axios.get(`https://api.tucasapropia.com/tcplocation/countries/${id}`)
        setData((prevState: any) => ({
          ...prevState,
          countryOriginResources: [...prevState.countryOriginResources, res.data],
        }))
        return res.data
      }
    }
  }

  const getOneInvestorData = async (investor: any) => {
    const promises = [
      getUser(investor.user),
      getBirthDepartment(investor.birthDepartment),
      getBirthCity(investor.birthCity),
      getResidenceCountry(investor.residenceCountry),
      getResidenceCity(investor.residenceCity),
      getCountryOriginResources(investor.countryOriginResources),
    ]
    const results = await Promise.all(promises)
    const [
      user,
      birthDepartment,
      birthCity,
      residenceCountry,
      residenceCity,
      countryOriginResources,
    ] = results
    return {
      ...investor,
      user,
      birthDepartment,
      birthCity,
      residenceCountry,
      residenceCity,
      countryOriginResources,
    }
  }

  const getMultipleInvestorsData = async (investors: any) => {
    const promises = investors.map(async (investor: any) => {
      const user = await getUser(investor.user)
      const birthDepartment = await getBirthDepartment(investor.birthDepartment)
      const birthCity = await getBirthCity(investor.birthCity)
      const residenceCountry = await getResidenceCountry(investor.residenceCountry)
      const residenceCity = await getResidenceCity(investor.residenceCity)
      const countryOriginResources = await getCountryOriginResources(
        investor.countryOriginResources
      )

      return {
        ...investor,
        user,
        birthDepartment,
        birthCity,
        residenceCountry,
        residenceCity,
        countryOriginResources,
      }
    })
    const results = await Promise.all(promises)

    return results
  }

  return {
    getOneInvestorData,
    getMultipleInvestorsData,
  }
}
