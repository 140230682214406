import {createTheme, ThemeProvider} from '@mui/material'
import {grey} from '@mui/material/colors'
import MUIDataTable, {MUIDataTableColumnDef} from 'mui-datatables'
import {useThemeMode} from '../../../_metronic/partials'

interface Props {
  title: string
  columns: MUIDataTableColumnDef[]
  data: Array<object>
  setId: React.Dispatch<React.SetStateAction<string | null>>
}

export function MUITable({title, columns, data, setId}: Props) {
  const {mode} = useThemeMode()

  const options: object = {
    filterType: 'multiselect',
    downloadOptions: {
      filename: `${title}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    print: 'false',
    rowsPerPageOptions: [10, 20, 50, 100],
    selectableRows: 'none',
    textLabels: {
      pagination: {
        next: 'Siguiente página',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'REINICIAR',
      },
      viewColumns: {
        title: 'Ver columnas',
        titleAria: 'Ver/Ocultar Columnas de la tabla',
      },
      selectedRows: {
        text: 'fila(s) seleccionada',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas',
      },
    },
    onRowClick: (rowData: string[]) => {
      setId(rowData[0])
    },
  }

  const darkTheme = createTheme({
    palette: {
      mode: mode === 'system' ? 'dark' : mode,
      ...(mode === 'dark' && {
        primary: grey,
        divider: '#2b2b40',
        background: {
          default: '#2b2b40',
          paper: '#2b2b40',
        },
        text: {
          primary: '#fff',
          secondary: '#2b2b40',
        },
      }),
    },
  })

  return (
    <ThemeProvider theme={darkTheme}>
      <MUIDataTable title={title} columns={columns} data={data} options={options} />
    </ThemeProvider>
  )
}
