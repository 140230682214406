import { Box, Typography } from '@mui/material'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { alertSuccess } from '../../../../_metronic/helpers/alerts'
import { UserContext } from '../../context/UserProvider'
import { InputMapSearch } from '../components/InputMapSearch'

interface Props {
  handleNext: () => void
  handleBack: () => void
  id: string | undefined
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
}

const API_URL = process.env.REACT_APP_SERVER_BASE_URL

const libraries: ('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[] = [
  'places',
]

function PropertyMap({handleBack, id, setActiveStep}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = useState(null)
  const {property, setProperty, initialState} = useContext(UserContext)

  const containerStyle = {
    width: '1000px',
    height: '450px',
  }

  const [center, setCenter] = useState<any>({
    lat: 4.739683863230216,
    lng: -74.02258394960383,
  })

  const [markerPosition, setMarkerPosition] = useState<any>(
    property.location.length !== 0
      ? {
          lat: property.location[1],
          lng: property.location[0],
        }
      : {
          lat: 4.735749141758348,
          lng: -74.02653216112414,
        }
  )

  function handleMarkerPosition(e: google.maps.MapMouseEvent) {
    const lng = e.latLng?.lng() ?? center.lng
    const lat = e.latLng?.lat() ?? center.lat

    setProperty((prevState: any) => ({
      ...prevState,
      location: [lng, lat],
    }))
    setMarkerPosition({
      lat,
      lng,
    })
  }

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBvDe9HiI__127i6Oa5iPK-4pfB339vwgg',
    libraries: libraries,
  })

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  const handleSubmit = async () => {
    try {
      if (id) {
        await axios.put(`${API_URL}/properties/${id}`, property)
      } else {
        setProperty(initialState)
        await axios.post(`${API_URL}/properties`, property)
      }
      setActiveStep(-1)
      alertSuccess(
        id
          ? 'El inmueble se ha actualizado correctamente'
          : 'El inmueble se ha creado correctamente'
      )
    } catch (error: any) {
      console.log(error.response)
    }
  }

  useEffect(() => {
    setCenter({
      lat: markerPosition.lat,
      lng: markerPosition.lng,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerPosition])

  useEffect(() => {
    if (property.location.length === 0) {
      setProperty((prevState: any) => ({
        ...prevState,
        location: [markerPosition.lng, markerPosition.lat],
      }))
      setCenter({
        lat: property.location[1],
        lng: property.location[0],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box mt={2}>
      <div className='card-body px-6 px-lg-10 px-xxl-15 py-10 bg-body'>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={13}
              onUnmount={onUnmount}
              options={{mapTypeControl: false}}
            >
              <InputMapSearch setMarkerPosition={setMarkerPosition} />
              <Marker position={markerPosition} draggable={true} onDragEnd={handleMarkerPosition} />
            </GoogleMap>
          )}
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Box sx={{display: 'flex', justifyContent: 'end', marginTop: 5}}>
            <span className={`btn btn-primary`} onClick={handleBack}>
              <Typography sx={{fontSize: '16px'}}>Anterior</Typography>
            </span>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'end', marginTop: 5}}>
            <button type='submit' className={`btn btn-primary`} onClick={handleSubmit}>
              <Typography sx={{fontSize: '16px'}}>
                {id ? 'Actualizar ' : 'Guardar '}cambios
              </Typography>
            </button>
          </Box>
        </Box>
      </div>
      <ToastContainer />
    </Box>
  )
}

export default React.memo(PropertyMap)
