import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import { useState } from 'react'
import { InvestorsBackendModel } from '../models/InvestmentModel'
interface Props {
  id: string
  label: string
  field: string
  formik: ReturnType<typeof useFormik>
  data: InvestorsBackendModel[]
}

interface Values {
  value: string
  label: string
}

export function AutoComplete({id, label, field, formik, data}: Props) {
  const options = data && data.map((investor) => ({value: investor.id, label: investor.userPhone}))
  const [inputValue, setInputValue] = useState<string>('')
  const [value, setValue] = useState<Values | null>(options?.[0])

  return (
    options && (
      <Autocomplete
        disablePortal
        id={id}
        value={value}
        onChange={(event: React.SyntheticEvent<Element, Event>, newValue) => {
          setValue(newValue)
          if (newValue) {
            const {value} = newValue
            formik.setFieldValue(field, value)
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        options={options}
        sx={{width: '100%'}}
        renderInput={(params) => (
          <>
            <TextField
              className='form-control'
              {...params}
              error={formik.touched[field] && formik.errors[field] ? true : false}
              helperText={(formik.touched[field] && formik.errors[field]) as string}
            />
          </>
        )}
      />
    )
  )
}
