import {TextField} from '@mui/material'
import {useFormik} from 'formik'
import React, {useState} from 'react'

interface Props {
  field: string
  label: string
  formik: ReturnType<typeof useFormik>
}

export const InputPrice = ({field, label, formik}: Props) => {
  const [isEditing, setIsEditing] = useState(false)

  const toCurrency = (number: number | string) => {
    if (number === '') return ''
    if (number === undefined) return ''
    if (Intl.NumberFormat) {
      const formatter = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      if (typeof number === 'number') {
        if (formatter.format(number) === '$ NaN') return ''
        return formatter.format(number)
      }
    }
    return number
  }

  const toggleEditing = () => {
    setIsEditing(!isEditing)
  }

  return isEditing ? (
    <TextField
      className='form-control'
      name={field}
      type='number'
      autoComplete='off'
      value={formik.getFieldProps(field).value || ''}
      onChange={formik.getFieldProps(field).onChange}
      onBlur={toggleEditing}
    />
  ) : (
    <TextField
      className='form-control'
      name={field}
      label={label}
      value={toCurrency(parseInt(formik.getFieldProps(field).value as string) || '')}
      onChange={(e: React.ChangeEvent) => {}}
      onFocus={toggleEditing}
      autoComplete='off'
      error={formik.touched[field] && formik.errors[field] ? true : false}
      helperText={(formik.touched[field] && formik.errors[field]) as string}
    />
  )
}
