import axios from 'axios'
import {InvestmentBackendModel} from '../models/InvestmentModel'

const API_URL = process.env.REACT_APP_SERVER_BASE_URL
const TCP_API_URL = process.env.REACT_APP_TCP_SERVER_BASE_URL
export function useGetInvestors() {
  async function getInvestor(id: string) {
    const investor = await axios.get(`${API_URL}/investors/${id}`)
    const user = await axios.get(`${TCP_API_URL}/users/${investor.data.user}`)

    return user.data.data.name
  }

  const getMultipleInvestorsData = async (properties: InvestmentBackendModel[]) => {
    const promises = properties.map(async (property) => {
      const investor = await getInvestor(property.investor.id)

      return {
        ...property,
        investor,
      }
    })
    const results = await Promise.all(promises)

    return results
  }

  return {getMultipleInvestorsData}
}
